import React, { useState, useEffect, useContext } from 'react';
import { TextField, MenuItem, Button } from '@material-ui/core';
import styles from './styles.module.css';
import DataTable from '../../utils/DataTable/';
import ListDialog from '../../popups/ListDialog';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import Loader from '../../utils/Loading';
import Info from '../../utils/Alert/Info';
import SearchIcon from '@material-ui/icons/Search';
import Error from '../../utils/Alert/Error';
import { BACKEND_URL, STORAGE_KEYS } from '../../../const';
import Success from '../../utils/Alert/Success';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import DateTimeHelpers from '../../../helpers/DateTimeHelpers';
import { ShopContext } from '../../../Context/ShopContext';
import { AlertSetter } from '../../utils/Alert/AlertSetter';
import RestrictionInfo from '../../utils/Alert/RestrictionInfo';
import StockReportService from '../../../services/StockReportService';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import { SUBSCRIPTION_TYPE } from '../../../const';

function StockReport() {
  const { shop } = useContext(ShopContext);

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [valueType, setValueType] = useState('all');
  const [stocks, setStocks] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [viewData, setViewData] = useState('');
  const [errorMsg, setErrorMsg] = useState();
  const [successMsg, setSuccessMsg] = useState();

  const subscriptionType = shop?.subscription?.type;

  useEffect(() => {
    if (subscriptionType && subscriptionType !== SUBSCRIPTION_TYPE.BASIC) {
      loadStockReports();
    }
    // eslint-disable-next-line
  }, [subscriptionType]);

  const loadStockReports = async () => {
    setLoadingIndicator(true);
    try {
      const data = {
        from_date: DateTimeHelpers.convertDateToDMY(fromDate),
        to_date: DateTimeHelpers.convertDateToDMY(toDate),
      };
      const resp = await StockReportService.getStockReport(data);
      setStocks(resp);
    } catch (err) {
      AlertSetter(setErrorMsg, err.message);
    }

    setLoadingIndicator(false);
  };
  const handleDateChange = async (from, to) => {
    setLoadingIndicator(true);
    setFromDate(from);
    setToDate(to);
    const data = {
      from_date: DateTimeHelpers.convertDateToDMY(from),
      to_date: DateTimeHelpers.convertDateToDMY(to),
    };
    const res = await StockReportService.getStockReport(data);
    setStocks(res);
    setLoadingIndicator(false);
  };

  const handleExportToExcel = async () => {
    const data = {
      from_date: DateTimeHelpers.convertDateToDMY(fromDate),
      to_date: DateTimeHelpers.convertDateToDMY(toDate),
      shop_id: localStorage.getItem(STORAGE_KEYS.SHOP_ID),
      token: localStorage.getItem(STORAGE_KEYS.TOKEN),
    };
    window.open(
      `${BACKEND_URL}/stock_reports/export_excel/?from_date=${data.from_date}&to_date=${data.to_date}&token=${data.token}&shop_id=${data.shop_id}`
    );
    AlertSetter(setSuccessMsg, 'Exported to excel successfully');
  };

  const _getFilteredStockEntries = (stockEntries, searchKey) => {
    if (!searchKey) {
      return _getFilteredStockEntriesBasedOnValueType(stockEntries, valueType);
    }

    const localStockEntries = stockEntries.filter(
      (stockEntry) =>
        stockEntry.item.plu.toString().includes(searchKey.toLowerCase()) ||
        stockEntry.item.name.toLowerCase().includes(searchKey.toLowerCase())
    );

    return _getFilteredStockEntriesBasedOnValueType(
      localStockEntries,
      valueType
    );
  };

  const _getFilteredStockEntriesBasedOnValueType = (
    stockEntries,
    valueType
  ) => {
    if (valueType === 'all') {
      return stockEntries;
    } else if (valueType === '+') {
      return stockEntries.filter((stock) => stock.closing_stock > 0);
    } else if (valueType === '-') {
      return stockEntries.filter((stock) => stock.closing_stock < 0);
    } else {
      return stockEntries.filter((stock) => stock.closing_stock === 0);
    }
  };

  const _getStockSummary = (stockEntries) => {
    let totalClosingStock = 0;
    let totalOpeningStock = 0;
    let totalInbound = 0;
    let totalOutbound = 0;
    let totalSalesStockValue = 0;
    stockEntries.forEach((stockEntry) => {
      totalClosingStock += stockEntry.closing_stock;
      totalOpeningStock += stockEntry.opening_stock;
      totalInbound += stockEntry.inbound;
      totalOutbound += stockEntry.outbound;
      totalSalesStockValue += stockEntry.closing_stock * stockEntry.item.price;
    });

    return {
      totalOpeningStock: totalOpeningStock.toFixed(2),
      totalClosingStock: totalClosingStock.toFixed(2),
      totalInbound: totalInbound.toFixed(2),
      totalOutbound: totalOutbound.toFixed(2),
      totalSalesStockValue: totalSalesStockValue.toFixed(2),
    };
  };

  const viewDetailsHandler = (data) => {
    if (!data || !data.transactions.length) {
      return setViewData(data);
    }
    const upadatedData = {
      ...data,
      transactions: [
        {
          type: 'Opening Stock',
          time: '  ',
          description: '',
          quantity: data.opening_stock,
        },
        ...data.transactions,
        {
          type: 'Closing Stock',
          time: '  ',
          description: '',
          quantity: data.closing_stock,
        },
      ],
    };
    setViewData(upadatedData);
  };
  const headerData = [
    {
      label: 'PLU',
      id: 'item.plu',
      type: 'text',
    },
    {
      label: 'Item Name',
      id: 'item.name',
      type: 'text',
    },
    {
      label: 'Opening Stock',
      id: 'opening_stock',
      type: 'floatQuantity',
    },
    {
      label: 'Inbound',
      id: 'inbound',
      type: 'floatAmount',
    },
    {
      label: 'Outbound',
      id: 'outbound',
      type: 'floatAmount',
    },
    {
      label: 'Closing Stock',
      id: 'closing_stock',
      type: 'floatQuantity',
    },
    {
      label: 'Stock Sales Value',
      id: 'stock_sales_value',
      type: 'callback',
      viewRender: (entry) => {
        return (entry.closing_stock * entry.item.price).toFixed(2);
      },
    },
    {
      label: 'Stock actions',
      id: 'btnViewDetail',
      type: 'button',
      title: 'View Details',
      clickHandler: viewDetailsHandler,
    },
  ];
  const transactionHeader = [
    {
      label: 'Type',
      id: 'type',
      type: 'text',
    },
    {
      label: 'Date',
      id: 'Date',
      type: 'callback',
      viewRender: (item) => {
        return item.time.split(' ')[1];
      },
    },
    {
      label: 'Time',
      id: 'time',
      type: 'callback',
      viewRender: (item) => {
        return item.time.split(' ')[0];
      },
    },
    {
      label: 'Details',
      id: 'description',
      type: 'text',
    },
    {
      label: 'Quantity',
      id: 'quantity',
      type: 'floatQuantity',
    },
  ];

  const filteredStockEntries = _getFilteredStockEntries(stocks, searchText);
  const summary = _getStockSummary(filteredStockEntries);
  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loadingIndicator} />
      <div className={styles.titleSec}>
        <h2 className={styles.title}>
          Stock<span className={styles.menuTitle}>Management</span>
        </h2>

        {subscriptionType && subscriptionType !== SUBSCRIPTION_TYPE.BASIC && (
          <div style={{ justifyContent: 'flex-end' }}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>Export As</label>
            </div>
            <Button
              variant="contained"
              color="primary"
              className={styles.actionBtn}
              style={{ backgroundColor: '#d81b60' }}
              onClick={handleExportToExcel}
            >
              <ImportExportIcon className={styles.actionBtnIcon} />
              Excel
            </Button>
          </div>
        )}
      </div>
      {subscriptionType && subscriptionType === SUBSCRIPTION_TYPE.BASIC && (
        <RestrictionInfo
          title={'Feature not available '}
          content={
            'To get stock report options upgrade subscription to premium'
          }
        />
      )}
      {subscriptionType && subscriptionType !== SUBSCRIPTION_TYPE.BASIC && (
        <>
          <div className={styles.changeable}>
            <div className={styles.filterSec}>
              <div className={styles.headTitle}>
                <h2 className={styles.subTitle}>Stock Report</h2>
              </div>
              <div className={styles.filerInputSec}>
                <div className={styles.searchSec}>
                  <input
                    type="text"
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    className={styles.searchInput}
                    placeholder="search items"
                  />
                  <SearchIcon className={styles.searchIcon} />
                </div>
              </div>
            </div>
            <div className={styles.actionButtons}>
              <div className={styles.filterDiv}>
                <div style={{ paddingBottom: '4px' }}>
                  <label className={styles.label}>From</label>
                </div>
                <div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableFuture
                      variant="outlined"
                      format="dd/MM/yyyy"
                      id="date-picker-from"
                      max={new Date()}
                      className={styles.dateBox}
                      value={fromDate}
                      onChange={(date) => {
                        handleDateChange(date, toDate);
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div className={styles.filterDiv}>
                <div style={{ paddingBottom: '4px' }}>
                  <label className={styles.label}>To</label>
                </div>
                <div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableFuture
                      variant="outlined"
                      format="dd/MM/yyyy"
                      id="date-picker-to"
                      className={styles.dateBox}
                      max={new Date()}
                      value={toDate}
                      onChange={(date) => {
                        handleDateChange(fromDate, date);
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'Change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div className={styles.filterDiv}>
                <div style={{ paddingBottom: '4px' }}>
                  <label className={styles.label}>
                    Filter By Closing Stock
                  </label>
                </div>
                <div>
                  <TextField
                    select
                    className={styles.textfeild}
                    name="fromDate"
                    size="small"
                    value={valueType}
                    variant="outlined"
                    color="primary"
                    onChange={(event) => {
                      setValueType(event.target.value);
                    }}
                  >
                    <MenuItem value={'all'}>All</MenuItem>
                    <MenuItem value={'+'}>+</MenuItem>
                    <MenuItem value={'0'}>0</MenuItem>
                    <MenuItem value={'-'}>-</MenuItem>
                  </TextField>
                </div>
              </div>
            </div>
          </div>
          {errorMsg && (
            <div className={styles.marginTop}>
              <Error title={errorMsg} />
            </div>
          )}
          {successMsg && (
            <div className={styles.marginTop}>
              <Success title={successMsg} />
            </div>
          )}
          {stocks &&
            (filteredStockEntries && filteredStockEntries.length ? (
              <DataTable
                columns={headerData}
                rows={filteredStockEntries}
                rowKey="item.id"
                summary={{
                  'item.name': 'Total',
                  closing_stock: summary.totalClosingStock,
                  opening_stock: summary.totalOpeningStock,
                  inbound: summary.totalInbound,
                  outbound: summary.totalOutbound,
                  stock_sales_value: summary.totalSalesStockValue,
                }}
              />
            ) : (
              <Info
                title={'You have no stock to list'}
                content={"You haven't any stock to list with given properties"}
              />
            ))}
          {viewData && (
            <ListDialog
              toggleItemsTab={viewDetailsHandler}
              tableHeader={transactionHeader}
              rowData={viewData.transactions}
              title={
                viewData
                  ? `Stock details of ${viewData.item.name}`
                  : 'stock details'
              }
              errTitle={'No transaction found for this item'}
            />
          )}
        </>
      )}
    </div>
  );
}

export default withConsoleBase(StockReport);
