import React, { useContext, useEffect, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { Button, MenuItem, TextField } from '@material-ui/core';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import CreateIcon from '@material-ui/icons/Create';
import styles from './styles.module.css';
import DataTable from '../../utils/DataTable';
import EditItemDialog from '../../popups/EditItemDialog';
import AddItemDialog from '../../popups/AddItemDialog';
import ItemService from '../../../services/ItemService';
import { saveAs } from 'file-saver';
import Loader from '../../utils/Loading';
import setDelay from '../../../helpers/LoadingDelay';
import Info from '../../utils/Alert/Info';
import Error from '../../utils/Alert/Error';
import Success from '../../utils/Alert/Success';
import { AlertSetter } from '../../utils/Alert/AlertSetter';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import { UserContext } from '../../../Context/UserContext';
import { ShopContext } from '../../../Context/ShopContext';

import { USER_PERMISSIONS, SUBSCRIPTION_TYPE } from '../../../const';

function ItemsList() {
  const { user } = useContext(UserContext);
  const { shop } = useContext(ShopContext);

  const [items, setItems] = useState([]);
  const [itemsFiltered, setItemsFiltered] = useState([]);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState('all');
  const [searchText, setSearchText] = useState('');
  const [addTab, setAddTab] = useState('');
  const [editData, setEditData] = useState('');
  const [excelData, setExcelData] = useState('');
  const [plus, setPlus] = useState([]);
  const [errorMsg, setErrorMsg] = useState();
  const [successMsg, setSuccessMsg] = useState();
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const subscriptionType = shop?.subscription?.type;

  useEffect(() => {
    handleImportExcel(excelData).then();
    // eslint-disable-next-line
  }, [excelData]);
  const toggleEditTab = (rowData = '') => {
    editData ? setEditData('') : setEditData(rowData);
  };
  const toggleAddTab = (move = '') => {
    if (move === 'save') getItems();
    addTab ? setAddTab('') : setAddTab('open');
  };

  const getItems = async () => {
    setLoadingIndicator(true);
    try {
      const res = await ItemService.getItems();
      const takenPlus = res.map((item) => item.plu);
      setPlus(takenPlus);
      setItems(res);
      setItemsFiltered(res);
    } catch (err) {
      setErrorMsg(err.message);
    }

    setDelay(setLoadingIndicator);
  };
  const handleExportScale = async () => {
    try {
      await ItemService.updatePlu();
      AlertSetter(setSuccessMsg, 'Item scale updated successfully');
    } catch (err) {
      AlertSetter(setErrorMsg, err.message);
    }
  };
  const handleExportExcel = async () => {
    try {
      const res = await ItemService.exportExcel();
      const blob = new Blob([res.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, `Items.xlsx`);
      AlertSetter(setSuccessMsg, 'Item exported to excel successfully');
    } catch (err) {
      AlertSetter(setErrorMsg, err.message);
    }
  };
  const handleImportExcel = async (e) => {
    if (excelData) {
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      try {
        await ItemService.importToExcel(formData);
        AlertSetter(setSuccessMsg, 'Item imported successufully');
      } catch (err) {
        AlertSetter(setErrorMsg, err.message);
      }
    }
  };
  const handleCategorySearch = (categId) => {
    setCategory(categId);
    if (categId === 'all') {
      setItemsFiltered(items);
    } else if (categId === 'none') {
      setItemsFiltered(items.filter((item) => item.category === null));
    } else {
      setItemsFiltered(
        items.filter((item) => item.category === Number(categId))
      );
    }
  };
  const handleItemsSearch = (value) => {
    setSearchText(value);
    if (category === 'all') {
      setItemsFiltered(
        items.filter(
          (item) =>
            item.name.toLowerCase().includes(value.toLowerCase()) ||
            item.plu.toString().includes(value)
        )
      );
    } else if (category === 'none') {
      if (value === '') {
        return setItemsFiltered(items.filter((item) => item.category === null));
      }
      setItemsFiltered(
        items.filter(
          (item) =>
            (item.category === null &&
              item.name.toLowerCase().includes(value.toLowerCase())) ||
            item.plu.toString().includes(value)
        )
      );
    } else {
      if (value === '') {
        return setItemsFiltered(
          items.filter((item) => item.category === Number(category))
        );
      }
      setItemsFiltered(
        items.filter(
          (item) =>
            (item.category === Number(category) &&
              item.name.toLowerCase().includes(value.toLowerCase())) ||
            item.plu.toString().includes(value)
        )
      );
    }
  };

  const onSuccessfulItemUpdate = (message) => {
    AlertSetter(setSuccessMsg, message);
    getItems();
  };

  const headerData = [
    {
      label: 'PLU',
      id: 'plu',
      type: 'text',
    },
    {
      label: 'Name',
      id: 'name',
      type: 'text',
      main: 'true',
    },
    {
      label: 'Price',
      id: 'price',
      type: 'text',
    },
    {
      label: 'Unit',
      id: 'unit',
      type: 'text',
    },
    {
      label: 'isAvailable',
      id: 'is_available',
      type: 'callback',
      viewRender: (item) => {
        return item.is_available ? 'Yes' : 'No';
      },
    },
  ];
  if (subscriptionType && subscriptionType === SUBSCRIPTION_TYPE.PREMIUM) {
    headerData.splice(2, 0, {
      label: 'Image',
      id: 'image',
      type: 'image',
    });
  }
  if (
    ((user || {}).shop_permissions || {}).products_permission ===
    USER_PERMISSIONS.WRITE
  ) {
    headerData.push({
      label: 'Update',
      id: 'updateItems',
      type: 'button',
      title: 'Edit Item',
      clickHandler: toggleEditTab,
    });
  }
  useEffect(() => {
    const getCategories = async () => {
      const res = await ItemService.getItemCategories();
      setCategories(res);
    };
    getCategories().then();
  }, []);
  useEffect(() => {
    getItems().then();
  }, []);
  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loadingIndicator} />

      <div className={styles.titleSec}>
        <h2 className={styles.title}>
          {' '}
          Item<span className={styles.menuTitle}>list</span>
        </h2>
      </div>
      <div className={styles.changeable}>
        <div className={styles.filterSec}>
          <div className={styles.headTitle}>
            <h2 className={styles.subTitle}>Products</h2>
          </div>
          <div className={styles.filerInputSec}>
            <TextField
              select
              variant="outlined"
              size="small"
              defaultValue="all"
              selected={category}
              onChange={(e) => {
                handleCategorySearch(e.target.value);
              }}
              className={styles.selectBox}
            >
              <MenuItem value="all">All</MenuItem>

              {categories.map((category) => (
                <MenuItem value={category.id}>{category.name}</MenuItem>
              ))}
              <MenuItem value="none">None</MenuItem>
            </TextField>
            <div className={styles.searchSec}>
              <input
                type="text"
                value={searchText}
                onChange={(e) => {
                  handleItemsSearch(e.target.value);
                }}
                className={styles.searchInput}
                placeholder="search items"
              />
              <SearchIcon className={styles.searchIcon} />
            </div>
          </div>
        </div>
        <div className={styles.actionButtons}>
          {((user || {}).shop_permissions || {}).products_permission ===
          USER_PERMISSIONS.READ ? (
            ''
          ) : (
            <div>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={styles.actionBtn}
                onClick={toggleAddTab}
              >
                <CreateIcon className={styles.actionBtnIcon} />
                New items
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={styles.actionBtn}
                style={{ backgroundColor: '#ff851b' }}
                onClick={handleImportExcel}
              >
                <input
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  type="file"
                  hidden
                  id="import"
                  onChange={(e) => {
                    setExcelData(e);
                  }}
                />
                <ImportExportIcon className={styles.actionBtnIcon} />
                <label for="import">Import excel</label>
              </Button>
            </div>
          )}

          <Button
            variant="contained"
            color="primary"
            size="small"
            className={styles.actionBtn}
            style={{ backgroundColor: '#605ca8' }}
            onClick={handleExportScale}
          >
            <ImportExportIcon className={styles.actionBtnIcon} />
            Export to scale
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={styles.actionBtn}
            style={{ backgroundColor: '#d81b60' }}
            onClick={handleExportExcel}
          >
            <ImportExportIcon className={styles.actionBtnIcon} />
            Export to excel
          </Button>
        </div>
      </div>

      {errorMsg && (
        <div className={styles.marginTop}>
          <Error title={errorMsg} />
        </div>
      )}
      {successMsg && (
        <div className={styles.marginTop}>
          <Success title={successMsg} />
        </div>
      )}

      {itemsFiltered &&
        (itemsFiltered.length ? (
          <DataTable
            columns={headerData}
            rows={itemsFiltered ? itemsFiltered : items}
            toggleEditTab={toggleEditTab}
          />
        ) : (
          <Info
            title={'You have no item to list'}
            content={"You haven't item with given properties"}
          />
        ))}

      {editData && (
        <EditItemDialog
          toggleEditTab={toggleEditTab}
          editData={editData}
          plus={plus}
          categories={categories}
          setErr={setErrorMsg}
          onSuccess={onSuccessfulItemUpdate}
        />
      )}

      {addTab && (
        <AddItemDialog
          toggleAddTab={toggleAddTab}
          plus={plus}
          categories={categories}
          setErrorMsg={setErrorMsg}
          onSuccess={onSuccessfulItemUpdate}
        />
      )}
    </div>
  );
}

export default withConsoleBase(ItemsList);
