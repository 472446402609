import { Link } from 'react-router-dom';
import { ROUTES } from '../../const';
import Alert from '@mui/material/Alert';
import { useEffect, useState } from 'react';
import ShopsHelper from '../../helpers/ShopsHelper';

/**
 * Display subscription ending soon alert if the current shop's subscription plan is going to end in the next 15 days
 * @param props - { shop }
 * @returns SubscriptionEndingSoonAlertView
 */
export default function SubscriptionEndingSoonAlert(props) {
  const { shop } = props;
  const [subscriptionRemainingDays, setSubscriptionRemainingDays] = useState();

  useEffect(() => {
    const remainingDays = ShopsHelper.getSubscriptionRemainingDays(shop);
    setSubscriptionRemainingDays(
      remainingDays < 15 ? remainingDays : undefined
    );
  }, [shop]);

  return (
    <div>
      {subscriptionRemainingDays && (
        <Alert severity="warning">
          Your subscription validity ends in {subscriptionRemainingDays} days.{' '}
          <strong>
            Click here to{' '}
            <Link to={ROUTES.SUBSCRIPTION_PLANS}> renew your plan</Link>
          </strong>
        </Alert>
      )}
    </div>
  );
}
