import { Grid, TextField, Button } from '@material-ui/core';
import styles from './styles.module.css';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from 'react';
import CustomerService from '../../services/CustomerService';
import { AlertSetter } from '../utils/Alert/AlertSetter';
import { DialogActions } from '@mui/material';

export default function EditCustomerDialog(props) {
  const { toggleEditTab, editData, setSuccessMsg } = props;
  const [name, setName] = useState(editData.name);
  const [phone, setPhone] = useState(editData.phone);
  const [location, setLocation] = useState(editData.location);

  const handleUpdateCustomer = async () => {
    const data = {
      ...editData,
      name,
      phone,
      location,
    };
    await CustomerService.updateCustomer(editData.id, data);
    AlertSetter(setSuccessMsg, 'Customer updated successfully');
    toggleEditTab();
  };

  return (
    <div className={styles.popUp}>
      <div className={styles.contentWrapper}>
        <div className={styles.headSec}>
          <h2 className={styles.editTitle}>Edit Customer</h2>
          <CloseIcon onClick={toggleEditTab} />
        </div>
        <div className={styles.inputSec}>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid
              item
              xs={4}
              sm={4}
              spacing={2}
              justify="flex-end"
              className={styles.inputLabelContainer}
            >
              <h3 className={styles.inputLabel}>Customer ID</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Customer Id"
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={editData.id}
              />
            </Grid>
          </Grid>

          <Grid container className={styles.inputRow} alignItems="center">
            <Grid
              item
              xs={4}
              sm={4}
              spacing={2}
              justify="flex-end"
              className={styles.inputLabelContainer}
            >
              <h3 className={styles.inputLabel}>Customer Points</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Customer Points"
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={editData.points}
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid
              item
              xs={4}
              sm={4}
              spacing={2}
              justify="flex-end"
              className={styles.inputLabelContainer}
            >
              <h3 className={styles.inputLabel}>Customer Name</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Customer Name"
                variant="outlined"
                size="small"
                fullWidth
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid
              item
              xs={4}
              sm={4}
              spacing={2}
              justify="flex-end"
              className={styles.inputLabelContainer}
            >
              <h3 className={styles.inputLabel}>Phone</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Phone"
                name="phone"
                size="small"
                variant="outlined"
                fullWidth
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid
              item
              xs={4}
              sm={4}
              spacing={2}
              justify="flex-end"
              className={styles.inputLabelContainer}
            >
              <h3 className={styles.inputLabel}>Place</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Place"
                name="location"
                size="small"
                variant="outlined"
                fullWidth
                value={location}
                onChange={(e) => {
                  setLocation(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              onClick={toggleEditTab}
              className={styles.closeBtn}
            >
              close
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={name === '' || phone === '' || location === ''}
              onClick={handleUpdateCustomer}
            >
              Update Customer
            </Button>
          </DialogActions>
        </div>
      </div>
    </div>
  );
}
