import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Button } from '@material-ui/core';
import styles from './styles.module.css';
import DataTable from '../../utils/DataTable/';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import SearchIcon from '@material-ui/icons/Search';
import PurchaseService from '../../../services/PurchaseService';
import ItemService from '../../../services/ItemService';
import { Link, useHistory } from 'react-router-dom';
import ListDialog from '../../popups/ListDialog';
import Loader from '../../utils/Loading';
import ConfirmPopup from '../../utils/Alert/ConfirmPopup';
import Info from '../../utils/Alert/Info';
import RestrictionInfo from '../../utils/Alert/RestrictionInfo';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import Error from '../../utils/Alert/Error';
import { USER_PERMISSIONS, ROUTES, SUBSCRIPTION_TYPE } from '../../../const';
import { ShopContext } from '../../../Context/ShopContext';
import { UserContext } from '../../../Context/UserContext';
import { AlertSetter } from '../../utils/Alert/AlertSetter';
import { PurchaseAlertContext } from '../../../Context/PurchaseAlertContext';
import Success from '../../utils/Alert/Success';
import DateFnsUtils from '@date-io/date-fns';
import DateTimeHelpers from '../../../helpers/DateTimeHelpers';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

function PurchaseHistory() {
  const { shop } = useContext(ShopContext);
  const { user } = useContext(UserContext);
  const { purchaseMsg } = useContext(PurchaseAlertContext);

  const [filterFromDate, setFilterFromDate] = useState(new Date());
  const [filterToDate, setFilterToDate] = useState(new Date());

  const [searchText, setSearchText] = useState('');
  const [purchases, setPurchase] = useState('');
  const [viewItems, setViewItems] = useState('');
  const [items, setItems] = useState();
  const [currency, setCurrency] = useState('');
  const [listTitle, setListTitle] = useState('');
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [deleteData, setDeleteData] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const [successMsg, setSuccessMsg] = useState();

  const subscriptionType = shop?.subscription?.type;
  const history = useHistory();
  const userHasPurchaseWritePermission =
    user?.shop_permissions?.purchase_permission === USER_PERMISSIONS.WRITE;

  const handleEditPurchase = (row) => {
    history.push(`/console/edit-purchase/${row.id}`);
  };
  const subTotal = (items) => {
    return items.reduce(function (a, b) {
      return a + b['total'];
    }, 0);
  };

  const handleItemsView = (row = '') => {
    if (row) {
      const selectedItems = row.items;
      setListTitle(`Purchased items form ${row.vendor} on ${row.date}`);
      let data = selectedItems.map((item) => ({
        ...item,
        name: (items.find((itm) => itm.id === item.item) || {}).name,
        total: Number(item.quantity) * Number(item.rate) + Number(item.vat),
      }));
      data = [
        ...data,
        {
          plu: '',
          name: '',
          quantity: '',
          rate: '',
          vat: 'Total',
          total: subTotal(data),
        },
      ];
      return setViewItems(data);
    }
    return setViewItems('');
  };
  const handleDeleteClick = (data) => setDeleteData(data);
  const handlePurchaseDeletion = async (row) => {
    const id = row.id;
    try {
      await PurchaseService.deletePurchase(id);
      getPurchase().then();
      AlertSetter(setSuccessMsg, 'Purchase deleted successufully');
    } catch (err) {
      setErrorMsg(err.message);
    }
  };

  const _getPurchasesFiltered = (purchases, searchKey) => {
    if (!searchKey) {
      return purchases;
    }

    const searchKeySmallCase = searchKey.toLowerCase();
    return (purchases || []).filter(
      (purchase) =>
        purchase.vendor.toLowerCase().includes(searchKeySmallCase) ||
        purchase.date.includes(searchKeySmallCase) ||
        purchase.total_amount.toString().includes(searchKeySmallCase)
    );
  };

  const _getPurchasesAmountSum = (purchases) => {
    if (!purchases || !purchases.length) {
      return 0;
    }

    let sum = 0;
    purchases.forEach((purchase) => (sum += purchase.total_amount));
    return sum.toFixed(2);
  };

  const headerData = [
    {
      label: 'Vendor Name',
      id: 'vendor',
      type: 'text',
    },
    {
      label: 'Purchase Date',
      id: 'date',
      type: 'text',
    },
    {
      label: 'Total Amount',
      id: 'total_amount',
      type: 'floatAmount',
    },
    {
      label: 'View items',
      title: 'View items',
      id: 'viewItems',
      type: 'button',
      clickHandler: handleItemsView,
    },
  ];
  if (userHasPurchaseWritePermission) {
    headerData.push({
      label: 'Edit',
      title: 'Edit',
      id: 'editPurchase',
      type: 'button',
      clickHandler: handleEditPurchase,
    });
    headerData.push({
      label: 'Delete',
      title: 'Delete',
      id: 'delet',
      type: 'button',
      clickHandler: handleDeleteClick,
    });
  }
  const listHeaderData = [
    {
      label: 'PLU',
      id: 'plu',
      type: 'text',
    },
    {
      label: 'Item Name',
      id: 'name',
      type: 'text',
    },
    {
      label: 'Price',
      id: 'rate',
      type: 'text',
    },
    {
      label: 'Quantity',
      id: 'quantity',
      type: 'text',
    },
    {
      label: 'GST',
      id: 'vat',
      type: 'text',
    },
    {
      label: 'Total',
      id: 'total',
      type: 'callback',
      viewRender: (item) => {
        return `${currency || ''} ${item.total.toFixed(2)}`;
      },
    },
  ];

  const _getLoadPurchaseRequestParams = useCallback(() => {
    return {
      date_start: DateTimeHelpers.getDateYMD(filterFromDate),
      date_end: DateTimeHelpers.getDateYMD(filterToDate),
    };
  }, [filterFromDate, filterToDate]);

  const getPurchase = useCallback(async () => {
    setLoadingIndicator(true);
    try {
      const params = _getLoadPurchaseRequestParams();
      const res = await PurchaseService.getPurchaseDetails(params);
      setPurchase(res);
      setCurrency(res.currency);
    } catch (err) {
      setErrorMsg(err.message);
    }
    setLoadingIndicator(false);
  }, [_getLoadPurchaseRequestParams]);

  useEffect(() => {
    const getItem = async () => {
      const res = await ItemService.getItems();
      setItems(res);
    };
    if (subscriptionType && subscriptionType !== SUBSCRIPTION_TYPE.BASIC) {
      getItem();
      getPurchase();
    }
  }, [subscriptionType, filterFromDate, filterToDate, getPurchase]);

  const purchasesToDisplay = _getPurchasesFiltered(purchases, searchText);
  const purchaseAmountSum = _getPurchasesAmountSum(purchases);
  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loadingIndicator} />
      <ConfirmPopup
        data={deleteData}
        handleClose={handleDeleteClick}
        handleOkay={handlePurchaseDeletion}
        alertTitle={'Confirm Delete'}
        successBtnName={'Delete'}
        alertContent={
          "Deleted Purchase History can't be restored, do you want to delete the Purchase History?"
        }
      />
      <div className={styles.titleSec}>
        <h2 className={styles.title}>
          Purchase <span className={styles.menuTitle}>Management</span>
        </h2>
      </div>
      {subscriptionType && subscriptionType === SUBSCRIPTION_TYPE.BASIC && (
        <RestrictionInfo
          title={'Feature not available '}
          content={
            'To get purchase history options upgrade subscription to premium'
          }
        />
      )}
      {subscriptionType && subscriptionType !== SUBSCRIPTION_TYPE.BASIC && (
        <>
          <div className={styles.changeable}>
            <div className={styles.filterSec}>
              <div className={styles.headTitle}>
                <h2 className={styles.subTitle}>Purchase History</h2>
              </div>
              <div className={styles.filerInputSec}>
                {userHasPurchaseWritePermission && (
                  <Link
                    to={ROUTES.NEW_PURCHASE}
                    style={{ textDecorationColor: 'transparent' }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{ backgroundColor: '#ff851b' }}
                      className={styles.actionBtn}
                    >
                      <AddShoppingCartIcon className={styles.actionBtnIcon} />
                      New Purchase
                    </Button>
                  </Link>
                )}
                <div className={styles.searchSec}>
                  <input
                    type="text"
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    className={styles.searchInput}
                    placeholder="search purchase"
                  />
                  <SearchIcon className={styles.searchIcon} />
                </div>
              </div>
            </div>
            <div className={styles.actionButtons}>
              <div className={styles.filterDiv}>
                <div style={{ paddingBottom: '4px' }}>
                  <label className={styles.label}>From</label>
                </div>
                <div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableFuture
                      variant="outlined"
                      format="dd/MM/yyyy"
                      id="date-picker-from"
                      className={styles.dateBox}
                      value={filterFromDate}
                      onChange={(date) => {
                        setFilterFromDate(date);
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'Change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div className={styles.filterDiv}>
                <div style={{ paddingBottom: '4px' }}>
                  <label className={styles.label}>To</label>
                </div>
                <div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableFuture
                      variant="outlined"
                      format="dd/MM/yyyy"
                      id="date-picker-to"
                      className={styles.dateBox}
                      value={filterToDate}
                      onChange={(date) => {
                        setFilterToDate(date);
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'Change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            </div>
          </div>
          {(errorMsg || purchaseMsg?.error) && (
            <div className={styles.marginTop}>
              <Error title={errorMsg || purchaseMsg.error} />
            </div>
          )}
          {successMsg && (
            <div className={styles.marginTop}>
              <Success title={successMsg} />
            </div>
          )}
          {purchases &&
            (purchasesToDisplay && purchasesToDisplay.length ? (
              <DataTable
                columns={headerData}
                rows={purchasesToDisplay}
                summary={{ date: 'Total', total_amount: purchaseAmountSum }}
              />
            ) : (
              <Info
                title={'You have no purchase to list'}
                content={
                  "You haven't any purchase to list with given properties"
                }
              />
            ))}

          {viewItems && (
            <ListDialog
              toggleItemsTab={handleItemsView}
              tableHeader={listHeaderData}
              rowData={viewItems}
              title={listTitle}
              emptyDataTitle="No purchase items"
              emptyDataContent=""
            />
          )}
        </>
      )}
    </div>
  );
}

export default withConsoleBase(PurchaseHistory);
