import BackendService from './BackendService';
import { STORAGE_KEYS } from '../const';
async function getBillSummaryReport(data = {}) {
  return BackendService.postData('/reports/bills/', data);
}
async function getBillSummaryExcel(data = {}) {
  return BackendService.post(
    '/reports/bills/',
    data,
    undefined,
    undefined,
    'arraybuffer'
  );
}
async function getBillSummaryCsv(data = {}) {
  return BackendService.post('/reports/bills/', data);
}
async function getBillDetails(data = {}) {
  return BackendService.postData('/reports/bills/details/', data);
}
async function exportBillToPdf(data = {}) {
  const authToken = localStorage.getItem(STORAGE_KEYS.TOKEN);
  return BackendService.getData(
    '/reports/bills/details/pdf/',
    {},
    {
      token: authToken,
      machine_no: data.machine_no,
      bill_no: data.bill_no,
    }
  );
}

const BillSummaryReportService = {
  getBillSummaryReport,
  getBillSummaryExcel,
  getBillSummaryCsv,
  getBillDetails,
  exportBillToPdf,
};

export default BillSummaryReportService;
