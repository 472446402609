import {
  Grid,
  TextField,
  Button,
  MenuItem,
  InputAdornment,
  IconButton,
  Tooltip,
  FormControlLabel,
  Checkbox,
  CardMedia,
} from '@material-ui/core';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useContext, useState, useEffect, useCallback } from 'react';
import styles from './styles.module.css';
import CloseIcon from '@material-ui/icons/Close';
import ItemService from '../../services/ItemService';
import AutoRenewIcon from '@material-ui/icons/Autorenew';
import RestrictionInfo from '../utils/Alert/RestrictionInfo';
import { ShopContext } from '../../Context/ShopContext';
import { AlertSetter } from '../utils/Alert/AlertSetter';
import { STORAGE_KEYS, SUBSCRIPTION_TYPE } from '../../const';
import SelectShopsDialog from './SelectShopsDialog';
import ShopService from '../../services/ShopService';
import Loader from '../utils/Loading';
import { DialogActions } from '@mui/material';

export default function EditItemDialog(props) {
  const { toggleEditTab, editData, plus, categories, onSuccess, setErr } =
    props;
  const { shop } = useContext(ShopContext);

  const [data, setData] = useState(editData);
  const [imageData, setImageData] = useState('');
  const [showPluTakenError, setShowPluTakenError] = useState(false);
  const [isShowSelectShopsDialog, setIsShowSelectShopsDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [itemAdvancedExisting, setItemAdvancedExisting] = useState({});
  const [itemAdvanced, setItemAdvanced] = useState({});

  const subscriptionType = shop?.subscription?.type || '';

  const readImageFile = (imageFile) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(imageFile);
      reader.onloadend = async () => {
        resolve(reader.result);
      };
    });
  };

  const loadItemAdvancedDetails = useCallback(async () => {
    setIsLoading(true);
    try {
      const itemAdvanced = await ItemService.getItemAdvanced(editData.id);
      setItemAdvanced(itemAdvanced);
      setItemAdvancedExisting(itemAdvanced);
    } catch (err) {
      AlertSetter(setErr, err.message);
    }
    setIsLoading(false);
  }, [setItemAdvanced, setErr, editData.id]);

  const hasAnyUpdate = () => {
    return (
      Object.keys(getRequestDataToUpdate()).length > 0 ||
      Object.keys(getRequestAdvancedDataToUpdate()).length > 0 ||
      imageData
    );
  };

  const getRequestDataToUpdate = () => {
    const requestData = {};
    if (String(editData.category) !== String(data.category)) {
      requestData.category = data.category;
    }
    if (String(editData.plu) !== String(data.plu)) {
      requestData.plu = data.plu;
    }
    if (String(editData.name) !== String(data.name)) {
      requestData.name = data.name;
    }
    if (String(editData.unit) !== String(data.unit)) {
      requestData.unit = data.unit;
    }
    if (String(editData.price) !== String(data.price)) {
      requestData.price = data.price;
    }
    if (String(editData.mrp) !== String(data.mrp)) {
      requestData.mrp = data.mrp || Number(data.price);
    }
    if (String(editData.cost) !== String(data.cost)) {
      requestData.cost = data.cost;
    }
    if (String(editData.stock) !== String(data.stock)) {
      requestData.stock = data.stock ? Number(data.stock) : 0;
    }
    if (String(editData.ean) !== String(data.ean)) {
      requestData.ean = data.ean || 'nil';
    }
    if (String(editData.tax) !== String(data.tax)) {
      requestData.tax = data.tax || 0;
    }
    if (editData.is_available !== data.is_available) {
      requestData.is_available = data.is_available;
    }
    if (editData.sync_enabled !== data.sync_enabled) {
      requestData.sync_enabled = data.sync_enabled;
    }
    return requestData;
  };

  const getRequestAdvancedDataToUpdate = () => {
    const requestAdvancedData = {};
    if (String(itemAdvanced.text1) !== String(itemAdvancedExisting.text1)) {
      requestAdvancedData.text1 = itemAdvanced.text1;
    }
    if (String(itemAdvanced.text2) !== String(itemAdvancedExisting.text2)) {
      requestAdvancedData.text2 = itemAdvanced.text2;
    }
    if (String(itemAdvanced.text3) !== String(itemAdvancedExisting.text3)) {
      requestAdvancedData.text3 = itemAdvanced.text3;
    }
    if (String(itemAdvanced.text4) !== String(itemAdvancedExisting.text4)) {
      requestAdvancedData.text4 = itemAdvanced.text4;
    }
    if (String(itemAdvanced.text5) !== String(itemAdvancedExisting.text5)) {
      requestAdvancedData.text5 = itemAdvanced.text5;
    }
    if (String(itemAdvanced.text6) !== String(itemAdvancedExisting.text6)) {
      requestAdvancedData.text6 = itemAdvanced.text6;
    }
    if (String(itemAdvanced.text7) !== String(itemAdvancedExisting.text7)) {
      requestAdvancedData.text7 = itemAdvanced.text7;
    }
    if (
      String(itemAdvanced.min_quantity) !==
      String(itemAdvancedExisting.min_quantity)
    ) {
      requestAdvancedData.min_quantity = itemAdvanced.min_quantity;
    }
    if (
      String(itemAdvanced.max_quantity) !==
      String(itemAdvancedExisting.max_quantity)
    ) {
      requestAdvancedData.max_quantity = itemAdvanced.max_quantity;
    }
    return requestAdvancedData;
  };

  const handleUpdateItem = async (shopIds) => {
    setIsLoading(true);
    try {
      const requestData = getRequestDataToUpdate();
      if (Object.keys(requestData).length > 0) {
        if (shopIds) {
          requestData.shop_ids = shopIds;
        }
        await ItemService.updateItem(editData.id, requestData);
      }

      const requestAdvancedData = getRequestAdvancedDataToUpdate();
      if (Object.keys(requestAdvancedData).length > 0) {
        await ItemService.updateItemAdvanced(requestAdvancedData, editData.id);
      }

      if (imageData) {
        const data = { image: await readImageFile(imageData) };
        await ItemService.uploadItemImage(data, editData.id);
      }
      onSuccess('Item updated successfully');
    } catch (err) {
      AlertSetter(setErr, err.message);
    }
    setIsLoading(false);

    toggleEditTab();
  };

  const checkIsPluAvailable = (value) => {
    setData({ ...data, plu: value });
    setShowPluTakenError(
      editData.plu !== data.plu && plus.find((plu) => plu === Number(value))
    );
  };

  const handleSuggestPlu = () => {
    setData({ ...data, plu: editData.plu || Math.max(...plus) + 1 });
  };

  const onEditItemButtonClicked = async () => {
    if (await shouldShowSelectOtherShopsDialog()) {
      setIsShowSelectShopsDialog(true);
      return;
    }
    await handleUpdateItem();
  };

  const closeOtherShopsSelectionDialog = () => {
    setIsShowSelectShopsDialog(false);
  };

  const onOtherShopsSelected = async (shopIds) => {
    setIsShowSelectShopsDialog(false);
    await handleUpdateItem(shopIds);
  };

  const shouldShowSelectOtherShopsDialog = async () => {
    if (Object.keys(getRequestDataToUpdate()).length === 0) {
      return false;
    }

    if (subscriptionType !== SUBSCRIPTION_TYPE.PREMIUM) {
      return false;
    }

    const localPreference = localStorage.getItem(
      STORAGE_KEYS.DONT_SHOW_OTHER_SHOPS_ON_ITEM_EDIT
    );
    if (localPreference && localPreference === String(true)) {
      return false;
    }

    setIsLoading(true);
    const shops = await ShopService.getShops();
    setIsLoading(false);
    return shops.length > 1;
  };

  useEffect(() => {
    if (subscriptionType === SUBSCRIPTION_TYPE.PREMIUM) {
      loadItemAdvancedDetails().then();
    }
  }, [loadItemAdvancedDetails, subscriptionType]);

  return (
    <>
      <Loader isOpen={isLoading} />

      {!isShowSelectShopsDialog && (
        <div className={styles.popUp}>
          <div className={styles.contentWrapper}>
            <div className={styles.headSec}>
              <h2 className={styles.editTitle}>Edit item</h2>
              <CloseIcon onClick={toggleEditTab} />
            </div>
            <div className={styles.inputSec}>
              {subscriptionType &&
                subscriptionType !== SUBSCRIPTION_TYPE.PREMIUM && (
                  <RestrictionInfo
                    title={'Input restrictions '}
                    content={
                      'To get all input options upgrade subscription to premium'
                    }
                  />
                )}

              <Grid container className={styles.inputRow} alignItems="center">
                <Grid
                  item
                  xs={4}
                  sm={4}
                  spacing={2}
                  justify="flex-end"
                  className={styles.inputLabelContainer}
                >
                  <h3 className={styles.inputLabel}>Item ID</h3>
                </Grid>
                <Grid item xs={8} sm={8}>
                  <TextField
                    label="Item Id"
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled
                    value={editData.id}
                  />
                </Grid>
              </Grid>

              <Grid container className={styles.inputRow} alignItems="center">
                <Grid
                  item
                  xs={4}
                  sm={4}
                  spacing={2}
                  justify="flex-end"
                  className={styles.inputLabelContainer}
                >
                  <h3 className={styles.inputLabel}>Item PLu</h3>
                </Grid>
                <Grid item xs={8} sm={8}>
                  <TextField
                    label={'Item PLU'}
                    variant="outlined"
                    size="small"
                    fullWidth
                    className={styles.numberInput}
                    error={showPluTakenError}
                    helperText={
                      showPluTakenError ? 'This PLU is already taken' : ''
                    }
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title="To suggest a plu">
                            <IconButton onClick={handleSuggestPlu}>
                              <AutoRenewIcon />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      checkIsPluAvailable(e.target.value);
                    }}
                    value={data.plu}
                  />
                </Grid>
              </Grid>
              <Grid container className={styles.inputRow} alignItems="center">
                <Grid
                  item
                  xs={4}
                  sm={4}
                  spacing={2}
                  justify="flex-end"
                  className={styles.inputLabelContainer}
                >
                  <h3 className={styles.inputLabel}>Item Name</h3>
                </Grid>
                <Grid item xs={8} sm={8}>
                  <TextField
                    label="Item Name"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={data.name}
                    onChange={(e) => {
                      setData({ ...data, name: e.target.value });
                    }}
                  />
                </Grid>
              </Grid>
              {subscriptionType &&
                subscriptionType === SUBSCRIPTION_TYPE.PREMIUM && (
                  <Grid
                    container
                    className={styles.inputRow}
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      spacing={2}
                      justify="flex-end"
                      className={styles.inputLabelContainer}
                    >
                      <h3 className={styles.inputLabel}>Item category</h3>
                    </Grid>
                    <Grid item xs={8} sm={8}>
                      <TextField
                        select
                        name="Unit"
                        size="small"
                        variant="outlined"
                        color="primary"
                        value={data.category}
                        onChange={(e) => {
                          setData({ ...data, category: e.target.value });
                        }}
                        fullWidth
                      >
                        <MenuItem value={''} selected>
                          None
                        </MenuItem>
                        {categories.map((category) => (
                          <MenuItem value={category.id}>
                            {category.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                )}
              {subscriptionType &&
                subscriptionType === SUBSCRIPTION_TYPE.PREMIUM && (
                  <Grid
                    container
                    className={styles.inputRow}
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      spacing={2}
                      justify="flex-end"
                      className={styles.inputLabelContainer}
                    >
                      <h3 className={styles.inputLabel}>Item Image</h3>
                    </Grid>
                    <Grid item xs={8} sm={8}>
                      <div className={styles.imageContainer}>
                        <CardMedia
                          image={
                            imageData
                              ? URL.createObjectURL(imageData)
                              : editData.image
                          }
                          className={styles.image}
                        />
                      </div>
                      <input
                        accept="image/*"
                        type="file"
                        hidden
                        id="upload"
                        onChange={(e) => {
                          setImageData(e.target.files[0]);
                        }}
                      />
                      <Button variant="contained" className={styles.uploadBtn}>
                        <label for="upload">select</label>
                      </Button>
                    </Grid>
                  </Grid>
                )}
              <Grid container className={styles.inputRow} alignItems="center">
                <Grid
                  item
                  xs={4}
                  sm={4}
                  spacing={2}
                  justify="flex-end"
                  className={styles.inputLabelContainer}
                >
                  <h3 className={styles.inputLabel}>Unit</h3>
                </Grid>
                <Grid item xs={8} sm={8}>
                  <TextField
                    select
                    name="unit"
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={data.unit}
                    onChange={(e) => {
                      setData({ ...data, unit: e.target.value });
                    }}
                  >
                    <MenuItem value="Kg">Kg</MenuItem>
                    <MenuItem value="Pcs">Pcs</MenuItem>
                  </TextField>
                </Grid>
              </Grid>
              <Grid container className={styles.inputRow} alignItems="center">
                <Grid
                  item
                  xs={4}
                  sm={4}
                  spacing={2}
                  justify="flex-end"
                  className={styles.inputLabelContainer}
                >
                  <h3 className={styles.inputLabel}>Price</h3>
                </Grid>
                <Grid
                  item
                  xs={
                    subscriptionType &&
                    subscriptionType === SUBSCRIPTION_TYPE.BASIC
                      ? 8
                      : 3
                  }
                  sm={
                    subscriptionType &&
                    subscriptionType === SUBSCRIPTION_TYPE.BASIC
                      ? 8
                      : 3
                  }
                >
                  <TextField
                    label="Price"
                    variant="outlined"
                    size="small"
                    type="number"
                    fullWidth
                    value={data.price}
                    onChange={(e) => {
                      setData({ ...data, price: e.target.value });
                    }}
                  />
                </Grid>
                {subscriptionType &&
                  subscriptionType !== SUBSCRIPTION_TYPE.BASIC && (
                    <>
                      <Grid
                        item
                        xs={2}
                        sm={2}
                        spacing={2}
                        justify="flex-end"
                        className={styles.inputLabelContainer}
                      >
                        <h3 className={styles.inputLabel}>Cost</h3>
                      </Grid>
                      <Grid item xs={3} sm={3}>
                        <TextField
                          label="Cost"
                          variant="outlined"
                          size="small"
                          type="number"
                          fullWidth
                          value={data.cost}
                          onChange={(e) => {
                            setData({ ...data, cost: e.target.value });
                          }}
                        />
                      </Grid>
                    </>
                  )}
              </Grid>
              {subscriptionType &&
                subscriptionType === SUBSCRIPTION_TYPE.PREMIUM && (
                  <Grid
                    container
                    className={styles.inputRow}
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      spacing={2}
                      justify="flex-end"
                      className={styles.inputLabelContainer}
                    >
                      <h3 className={styles.inputLabel}>MRP</h3>
                    </Grid>
                    <Grid item xs={8} sm={8}>
                      <TextField
                        label="EAN"
                        variant="outlined"
                        size="small"
                        fullWidth
                        name="mrp"
                        value={data.mrp}
                        onChange={(e) => {
                          setData({ ...data, mrp: e.target.value });
                        }}
                      />
                    </Grid>
                  </Grid>
                )}
              <Grid container className={styles.inputRow} alignItems="center">
                <Grid
                  item
                  xs={4}
                  sm={4}
                  spacing={2}
                  justify="flex-end"
                  className={styles.inputLabelContainer}
                >
                  <h3 className={styles.inputLabel}>EAN</h3>
                </Grid>
                <Grid item xs={8} sm={8}>
                  <TextField
                    label="EAN"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={data.ean}
                    onChange={(e) => {
                      setData({ ...data, ean: e.target.value });
                    }}
                  />
                </Grid>
              </Grid>
              {subscriptionType &&
                subscriptionType !== SUBSCRIPTION_TYPE.BASIC && (
                  <>
                    <Grid
                      container
                      className={styles.inputRow}
                      alignItems="center"
                    >
                      <Grid
                        item
                        xs={4}
                        sm={4}
                        spacing={2}
                        justify="flex-end"
                        className={styles.inputLabelContainer}
                      >
                        <h3 className={styles.inputLabel}>GST</h3>
                      </Grid>
                      <Grid item xs={8} sm={8}>
                        <TextField
                          label="GST %"
                          variant="outlined"
                          size="small"
                          type="number"
                          fullWidth
                          value={data.tax}
                          onChange={(e) => {
                            setData({ ...data, tax: e.target.value });
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      className={styles.inputRow}
                      alignItems="center"
                    >
                      <Grid
                        item
                        xs={4}
                        sm={4}
                        spacing={2}
                        justify="flex-end"
                        className={styles.inputLabelContainer}
                      >
                        <h3 className={styles.inputLabel}>Stock</h3>
                      </Grid>
                      <Grid item xs={8} sm={8}>
                        <TextField
                          label="Stock"
                          variant="outlined"
                          size="small"
                          type="number"
                          fullWidth
                          value={data.stock}
                          onChange={(e) => {
                            setData({ ...data, stock: e.target.value });
                          }}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
              {subscriptionType &&
                subscriptionType === SUBSCRIPTION_TYPE.PREMIUM && (
                  <>
                    <Grid container alignItems="center" justify="flex-start">
                      <Grid item xs={4} />
                      <Grid item xs={4}>
                        <FormControlLabel
                          value="end"
                          control={
                            <Checkbox
                              color="primary"
                              checked={data.is_available}
                              onChange={() =>
                                setData({
                                  ...data,
                                  is_available: !data.is_available,
                                })
                              }
                            />
                          }
                          label="Available for sale"
                          labelPlacement="end"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <FormControlLabel
                          value="end"
                          control={
                            <Checkbox
                              color="primary"
                              checked={data.sync_enabled}
                              onChange={() =>
                                setData({
                                  ...data,
                                  sync_enabled: !data.sync_enabled,
                                })
                              }
                            />
                          }
                          label="GeKart SYNC"
                          labelPlacement="end"
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      className={styles.inputRow}
                      alignItems="center"
                    >
                      <Grid item xs={12} sm={12}>
                        <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Advanced</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid
                              container
                              className={styles.inputRow}
                              alignItems="center"
                            >
                              <Grid
                                item
                                xs={4}
                                sm={4}
                                spacing={2}
                                className={styles.inputLabelContainer}
                              >
                                <h3 className={styles.inputLabel}>Text 1</h3>
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  placeholder="Text 1"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  type="text"
                                  value={itemAdvanced.text1}
                                  onChange={(e) => {
                                    setItemAdvanced({
                                      ...itemAdvanced,
                                      text1: e.target.value,
                                    });
                                  }}
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              className={styles.inputRow}
                              alignItems="center"
                            >
                              <Grid
                                item
                                xs={4}
                                sm={4}
                                spacing={2}
                                className={styles.inputLabelContainer}
                              >
                                <h3 className={styles.inputLabel}>Text 2</h3>
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  placeholder="Text 2"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  type="text"
                                  value={itemAdvanced.text2}
                                  onChange={(e) => {
                                    setItemAdvanced({
                                      ...itemAdvanced,
                                      text2: e.target.value,
                                    });
                                  }}
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              className={styles.inputRow}
                              alignItems="center"
                            >
                              <Grid
                                item
                                xs={4}
                                sm={4}
                                spacing={2}
                                className={styles.inputLabelContainer}
                              >
                                <h3 className={styles.inputLabel}>Text 3</h3>
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  placeholder="Text 3"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  type="text"
                                  value={itemAdvanced.text3}
                                  onChange={(e) => {
                                    setItemAdvanced({
                                      ...itemAdvanced,
                                      text3: e.target.value,
                                    });
                                  }}
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              className={styles.inputRow}
                              alignItems="center"
                            >
                              <Grid
                                item
                                xs={4}
                                sm={4}
                                spacing={2}
                                className={styles.inputLabelContainer}
                              >
                                <h3 className={styles.inputLabel}>Text 4</h3>
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  placeholder="Text 4"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  type="text"
                                  value={itemAdvanced.text4}
                                  onChange={(e) => {
                                    setItemAdvanced({
                                      ...itemAdvanced,
                                      text4: e.target.value,
                                    });
                                  }}
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              className={styles.inputRow}
                              alignItems="center"
                            >
                              <Grid
                                item
                                xs={4}
                                sm={4}
                                spacing={2}
                                className={styles.inputLabelContainer}
                              >
                                <h3 className={styles.inputLabel}>Text 5</h3>
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  placeholder="Text 5"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  type="text"
                                  value={itemAdvanced.text5}
                                  onChange={(e) => {
                                    setItemAdvanced({
                                      ...itemAdvanced,
                                      text5: e.target.value,
                                    });
                                  }}
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              className={styles.inputRow}
                              alignItems="center"
                            >
                              <Grid
                                item
                                xs={4}
                                sm={4}
                                spacing={2}
                                className={styles.inputLabelContainer}
                              >
                                <h3 className={styles.inputLabel}>Text 6</h3>
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  placeholder="Text 6"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  type="text"
                                  value={itemAdvanced.text6}
                                  onChange={(e) => {
                                    setItemAdvanced({
                                      ...itemAdvanced,
                                      text6: e.target.value,
                                    });
                                  }}
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              className={styles.inputRow}
                              alignItems="center"
                            >
                              <Grid
                                item
                                xs={4}
                                sm={4}
                                spacing={2}
                                className={styles.inputLabelContainer}
                              >
                                <h3 className={styles.inputLabel}>Text 7</h3>
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  placeholder="Text 7"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  type="text"
                                  value={itemAdvanced.text7}
                                  onChange={(e) => {
                                    setItemAdvanced({
                                      ...itemAdvanced,
                                      text7: e.target.value,
                                    });
                                  }}
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              className={styles.inputRow}
                              alignItems="center"
                            >
                              <Grid
                                item
                                xs={4}
                                sm={4}
                                spacing={2}
                                className={styles.inputLabelContainer}
                              >
                                <h3 className={styles.inputLabel}>Min Qty</h3>
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  placeholder="Min Qty"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  type="number"
                                  value={itemAdvanced.min_quantity}
                                  onChange={(e) => {
                                    setItemAdvanced({
                                      ...itemAdvanced,
                                      min_quantity: e.target.value,
                                    });
                                  }}
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              className={styles.inputRow}
                              alignItems="center"
                            >
                              <Grid
                                item
                                xs={4}
                                sm={4}
                                spacing={2}
                                className={styles.inputLabelContainer}
                              >
                                <h3 className={styles.inputLabel}>Max Qty</h3>
                              </Grid>
                              <Grid item xs={8} sm={8}>
                                <TextField
                                  placeholder="Max Qty"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  type="number"
                                  value={itemAdvanced.max_quantity}
                                  onChange={(e) => {
                                    setItemAdvanced({
                                      ...itemAdvanced,
                                      max_quantity: e.target.value,
                                    });
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    </Grid>
                  </>
                )}

              <DialogActions>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={toggleEditTab}
                  className={styles.closeBtn}
                >
                  close
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    data.name === '' ||
                    data.price === '' ||
                    data.cost === '' ||
                    data.stock === '' ||
                    showPluTakenError ||
                    !data.plu ||
                    !hasAnyUpdate()
                  }
                  onClick={onEditItemButtonClicked}
                >
                  Update Item
                </Button>
              </DialogActions>
            </div>
          </div>
        </div>
      )}
      {isShowSelectShopsDialog && (
        <SelectShopsDialog
          onClose={closeOtherShopsSelectionDialog}
          onShopsSelected={onOtherShopsSelected}
          message={`Do you want to update item with PLU '${data.plu}' in all shops?`}
        />
      )}
    </>
  );
}
