import styles from './styles.module.css';
import { useState, useContext } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import DataTable from '../utils/DataTable';
import Info from '../utils/Alert/Info';
import { Grid, TextField, Button, Tooltip } from '@material-ui/core';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CustomerService from '../../services/CustomerService';
import { AlertSetter } from '../utils/Alert/AlertSetter';
import Loader from '../utils/Loading';
import setDelay from '../../helpers/LoadingDelay';
import { UserContext } from '../../Context/UserContext';
import { USER_PERMISSIONS } from '../../const';

export default function BillDetailsDialog(props) {
  const { user } = useContext(UserContext);
  const [customerName, setCustomerName] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [customer, setCustomer] = useState('');
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const {
    toggleItemsTab,
    rowData,
    details,
    customers,
    setErrorMsg,
    setSuccessMsg,
  } = props;
  const listHeader = [
    {
      id: 'item.plu',
      label: 'PLU',
      type: 'text',
    },
    {
      id: 'item.name',
      label: 'Name',
      type: 'text',
    },
    {
      id: 'qty',
      label: 'Quantity',
      type: 'floatQuantity',
    },
    {
      id: 'item.price',
      label: 'Price',
      type: 'floatAmount',
    },
    {
      id: 'total',
      label: 'Total',
      type: 'floatAmount',
    },
  ];
  const handleBillAssign = async () => {
    const data = {
      bill_no: details.bill_no,
      machine_no: details.machine_no,
      customer_id: customer.id,
    };
    setLoadingIndicator(true);
    try {
      await CustomerService.assignCustomer(data);
      AlertSetter(setSuccessMsg, 'Sales assigned successfully');
    } catch (err) {
      AlertSetter(setErrorMsg, err.message);
    }
    setDelay(setLoadingIndicator);
    toggleItemsTab('');
  };
  return (
    <div className={styles.popUp}>
      <div className={styles.contentWrapper}>
        <Loader isOpen={loadingIndicator} />
        <div className={styles.headSec}>
          <h2 className={styles.editTitle}>Bill Details</h2>
          <CloseIcon
            onClick={() => {
              toggleItemsTab('');
            }}
          />
        </div>
        {rowData.customer != null ? (
          <div className={styles.customerDetails}>
            <div>
              <h3 className={styles.editTitle}>Customer ID</h3>
              <h3 className={styles.customerData}>{rowData.customer.id}</h3>
            </div>
            <div>
              <h3 className={styles.editTitle}>Name</h3>
              <h3 className={styles.customerData}>{rowData.customer.name}</h3>
            </div>
            <div>
              <h3 className={styles.editTitle}>Location</h3>
              <h3 className={styles.customerData}>
                {rowData.customer.location}
              </h3>
            </div>
          </div>
        ) : (
          <div className={styles.subSection}>
            {((user || {}).shop_permissions || {}).reports_permission ===
            USER_PERMISSIONS.READ ? (
              ''
            ) : (
              <Grid
                container
                className={styles.inputRow}
                alignItems="center"
                spacing={2}
                style={{ marginBottom: 0 }}
              >
                <Grid item xs={5} sm={5}>
                  <Autocomplete
                    value={customer.id}
                    onChange={(event, newCustomer) => {
                      setCustomer(newCustomer);
                      setCustomerName(newCustomer.name);
                    }}
                    disableClearable
                    inputValue={customerId}
                    onInputChange={(e, value) => {
                      setCustomerId(value);
                    }}
                    getOptionLabel={(option) => `${option.id}`}
                    options={customers}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Customer ID"
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={5} sm={5}>
                  <Autocomplete
                    value={customer.name}
                    onChange={(event, newCustomer) => {
                      setCustomer(newCustomer);
                      setCustomerId(newCustomer.id);
                    }}
                    disableClearable
                    inputValue={customerName}
                    onInputChange={(e, value) => {
                      setCustomerName(value);
                    }}
                    getOptionLabel={(option) => `${option.name}`}
                    options={customers}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Customer Name"
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Tooltip title="Assign bill to customer" placement="bottom">
                  <Grid item xs={2} sm={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      disabled={customerId === '' || customerName === ''}
                      onClick={handleBillAssign}
                    >
                      <AssignmentIndIcon />
                    </Button>
                  </Grid>
                </Tooltip>
              </Grid>
            )}
          </div>
        )}

        <div className={styles.tableContent1}>
          {rowData.items ? (
            <DataTable
              columns={listHeader}
              rows={rowData.items.filter(
                (data) => (data.item || {}).name !== 'Discount'
              )}
            />
          ) : (
            <Info
              title={'Bill Details not found'}
              content={'This bill have no items to list up'}
            />
          )}
        </div>
      </div>
    </div>
  );
}
