import { Link } from 'react-router-dom';
import { useEffect, useContext } from 'react';
import styles from './styles.module.css';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SettingsIcon from '@material-ui/icons/Settings';
import PersonIcon from '@material-ui/icons/Person';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssessmentIcon from '@material-ui/icons/Assessment';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import DevicesIcon from '@material-ui/icons/Devices';
import PeopleIcon from '@material-ui/icons/People';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { Grid } from '@material-ui/core';
import { USER_PERMISSIONS, ROUTES } from '../../../const';
import { selectedTabContext } from '../../../Context/SelectedTabContext';
import ShopSettingsService from '../../../services/ShopSettingsService';
import UserService from '../../../services/UserService';
import { ShopContext } from '../../../Context/ShopContext';
import { UserContext } from '../../../Context/UserContext';
const tabStyleActive = {
  backgroundColor: '#ff7f00',
  color: '#fff',
};

const tabStyle = {
  color: '#000',
};
export default function NavBar() {
  const { selectedTab, setSelectedTab } = useContext(selectedTabContext);
  const { shop, setShop } = useContext(ShopContext);
  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    const loadShopProfile = async () => {
      const res = await ShopSettingsService.loadShopProfile();
      setShop(res);
    };
    const loadUserProfile = async () => {
      const resUser = await UserService.getUserProfile();
      setUser(resUser);
    };
    loadUserProfile();
    loadShopProfile();
  }, [setUser, setShop, user, shop]);

  const handleSelectedTabChange = (tab) => {
    setSelectedTab(tab);
  };
  const itemTabRoutes = ['items', 'categories'];
  const salesTabRoutes = ['item-summary', 'bill-summary', 'itemised-bill'];
  return (
    <Grid container spacing={1}>
      <Grid item xs={8} sm={12} className={styles.navBar}>
        <div style={{ paddingTop: 10, paddingLeft: 10 }}>
          <span className={styles.Shop}>{shop && shop.shop_name}</span>
        </div>
        <div className={styles.profileSec}>
          <span className={`${styles.iconHolder} ${styles.navIcon}`}>
            <PersonIcon style={{ color: 'white', fontSize: '12px' }} />
          </span>
          <span className={styles.username}>{user && user.username}</span>
        </div>

        {((user || {}).shop_permissions || {}).reports_permission ===
        USER_PERMISSIONS.DENY ? (
          ''
        ) : (
          <Link
            to={ROUTES.DASHBOARD}
            style={{ textDecorationColor: 'transparent' }}
          >
            <div
              className={`${styles.navItem} ${styles.mt - 4}`}
              style={selectedTab === 'dashboard' ? tabStyleActive : tabStyle}
              onClick={() => {
                handleSelectedTabChange('dashboard');
              }}
            >
              <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                <DashboardIcon
                  fontSize="small"
                  className={styles.navBarIconColor}
                />
              </span>
              <span className={styles.title}>Dashboard</span>
            </div>
          </Link>
        )}
        {((user || {}).shop_permissions || {}).products_permission ===
        USER_PERMISSIONS.DENY ? (
          ''
        ) : (
          <div
            className={`${styles.navItem} ${styles.mt - 4}`}
            style={
              itemTabRoutes.includes(selectedTab) || selectedTab === 'itemTab'
                ? tabStyleActive
                : tabStyle
            }
            onClick={() => {
              handleSelectedTabChange('itemTab');
            }}
          >
            <span className={`${styles.iconHolder} ${styles.navIcon}`}>
              <LocalOfferIcon
                fontSize="small"
                className={styles.navBarIconColor}
              />
            </span>
            <span className={styles.title}>Items</span>
          </div>
        )}
        {itemTabRoutes.includes(selectedTab) || selectedTab === 'itemTab' ? (
          <div
            style={{
              backgroundColor: '#1A2226',
              padding: '4px',
            }}
          >
            <Link
              to={ROUTES.ITEMS}
              style={{ textDecorationColor: 'transparent' }}
            >
              <div className={styles.subMenu}>
                <span>Products</span>
              </div>
            </Link>
            <Link
              to={ROUTES.CATEGORIES}
              style={{ textDecorationColor: 'transparent' }}
            >
              <div className={styles.subMenu}>
                <span>Categories</span>
              </div>
            </Link>
          </div>
        ) : null}
        {((user || {}).shop_permissions || {}).devices_permission ===
        USER_PERMISSIONS.DENY ? (
          ''
        ) : (
          <Link
            to={ROUTES.DEVICES}
            style={{ textDecorationColor: 'transparent' }}
          >
            <div
              className={`${styles.navItem} ${styles.mt - 4}`}
              style={selectedTab === 'devices' ? tabStyleActive : tabStyle}
              onClick={() => {
                handleSelectedTabChange('devices');
              }}
            >
              <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                <DevicesIcon
                  fontSize="small"
                  className={styles.navBarIconColor}
                />
              </span>
              <span className={styles.title}>Devices</span>
            </div>
          </Link>
        )}

        {((user || {}).shop_permissions || {}).customers_permission ===
        USER_PERMISSIONS.DENY ? (
          ''
        ) : (
          <Link
            to={ROUTES.CUSTOMERS}
            style={{ textDecorationColor: 'transparent' }}
          >
            <div
              className={`${styles.navItem} ${styles.mt - 4}`}
              style={selectedTab === 'customers' ? tabStyleActive : tabStyle}
              onClick={() => {
                handleSelectedTabChange('customers');
              }}
            >
              <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                <PeopleIcon
                  fontSize="small"
                  className={styles.navBarIconColor}
                />
              </span>
              <span className={styles.title}>Customers</span>
            </div>
          </Link>
        )}

        {((user || {}).shop_permissions || {}).purchase_permission ===
        USER_PERMISSIONS.DENY ? (
          ''
        ) : (
          <Link
            to={ROUTES.PURCHASE_HISTORY}
            style={{ textDecorationColor: 'transparent' }}
          >
            <div
              className={styles.navItem}
              style={
                selectedTab === 'purchase-history' ? tabStyleActive : tabStyle
              }
              onClick={() => {
                handleSelectedTabChange('purchase-history');
              }}
            >
              <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                <ShoppingCartIcon
                  fontSize="small"
                  className={styles.navBarIconColor}
                />
              </span>
              <span className={styles.title}>Purchases</span>
            </div>
          </Link>
        )}

        {((user || {}).shop_permissions || {}).expense_permission ===
        USER_PERMISSIONS.DENY ? (
          ''
        ) : (
          <Link
            to={ROUTES.EXPENSES}
            style={{ textDecorationColor: 'transparent' }}
          >
            <div
              className={styles.navItem}
              style={selectedTab === 'expenses' ? tabStyleActive : tabStyle}
              onClick={() => {
                handleSelectedTabChange('expenses');
              }}
            >
              <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                <CreditCardIcon
                  fontSize="small"
                  className={styles.navBarIconColor}
                />
              </span>
              <span className={styles.title}>Expenses</span>
            </div>
          </Link>
        )}

        {((user || {}).shop_permissions || {}).damage_permission ===
        USER_PERMISSIONS.DENY ? (
          ''
        ) : (
          <Link
            to={ROUTES.DAMAGE_HISTORY}
            style={{ textDecorationColor: 'transparent' }}
          >
            <div
              className={`${styles.navItem} ${styles.mt - 4}`}
              style={
                selectedTab === 'damage-history' ? tabStyleActive : tabStyle
              }
              onClick={() => {
                handleSelectedTabChange('damage-history');
              }}
            >
              <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                <AssessmentIcon
                  fontSize="small"
                  className={styles.navBarIconColor}
                />
              </span>
              <span className={styles.title}>Stock Damages</span>
            </div>
          </Link>
        )}

        {((user || {}).shop_permissions || {}).stock_permission ===
        USER_PERMISSIONS.DENY ? (
          ''
        ) : (
          <Link
            to={ROUTES.STOCK_REPORT}
            style={{ textDecorationColor: 'transparent' }}
          >
            <div
              className={`${styles.navItem} ${styles.mt - 4}`}
              style={selectedTab === 'stock-report' ? tabStyleActive : tabStyle}
              onClick={() => {
                handleSelectedTabChange('stock-report');
              }}
            >
              <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                <SyncAltIcon
                  fontSize="small"
                  className={styles.navBarIconColor}
                />
              </span>
              <span className={styles.title}>Stock Reports</span>
            </div>
          </Link>
        )}

        {((user || {}).shop_permissions || {}).reports_permission ===
        USER_PERMISSIONS.DENY ? (
          ''
        ) : (
          <div
            className={`${styles.navItem} ${styles.mt - 1}`}
            style={
              salesTabRoutes.includes(selectedTab) || selectedTab === 'salesTab'
                ? tabStyleActive
                : tabStyle
            }
            onClick={() => {
              handleSelectedTabChange('salesTab');
            }}
          >
            <span className={`${styles.iconHolder} ${styles.navIcon}`}>
              <AssignmentIcon
                fontSize="small"
                className={styles.navBarIconColor}
              />
            </span>
            <span className={styles.title}>Sales Repots</span>
          </div>
        )}
        {salesTabRoutes.includes(selectedTab) || selectedTab === 'salesTab' ? (
          <div
            style={{
              backgroundColor: '#1A2226',
              padding: '4px',
            }}
          >
            <Link
              to={ROUTES.ITEM_SUMMARY}
              style={{ textDecorationColor: 'transparent' }}
            >
              <div className={styles.subMenu}>
                <span>Item Summary Report</span>
              </div>
            </Link>
            <Link
              to={ROUTES.ITEMISED_BILL}
              style={{ textDecorationColor: 'transparent' }}
            >
              <div className={styles.subMenu}>
                <span>Itemised Bill Report</span>
              </div>
            </Link>
            <Link
              to={ROUTES.BILL_SUMMARY}
              style={{ textDecorationColor: 'transparent' }}
            >
              <div className={styles.subMenu}>
                <span>Bill Summary Report</span>
              </div>
            </Link>
          </div>
        ) : null}

        {((user || {}).shop_permissions || {}).shop_permission ===
        USER_PERMISSIONS.DENY ? (
          ''
        ) : (
          <Link
            to={ROUTES.SETTINGS}
            style={{ textDecorationColor: 'transparent' }}
          >
            <div
              className={`${styles.navItem} ${styles.mt - 4}`}
              style={selectedTab === 'settings' ? tabStyleActive : tabStyle}
              onClick={() => {
                handleSelectedTabChange('settings');
              }}
            >
              <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                <SettingsIcon
                  fontSize="small"
                  className={styles.navBarIconColor}
                />
              </span>
              <span className={styles.title}>Settings</span>
            </div>
          </Link>
        )}
      </Grid>
    </Grid>
  );
}
