import BackendService from './BackendService';

async function getMachines() {
  return BackendService.getData('/machines/');
}
async function getBillFormat(machineNumber) {
  return BackendService.getData(
    '/machine/bill_format/',
    {},
    { machine_number: machineNumber }
  );
}
async function updateBillFormat(data) {
  return BackendService.postData('/machine/bill_format/', data);
}

const BillFormatService = {
  getMachines,
  getBillFormat,
  updateBillFormat,
};

export default BillFormatService;
