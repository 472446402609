import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { CardMedia } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import styles from './styles.module.css';

export default function DataTable(props) {
  const columns = props.columns || [];
  const rows = props.rows || [];
  const rowKey = props.rowKey || 'id';
  const rowClicker = columns.find((col) => col.type === 'rowClick') || '';

  const getValueForCell = (data, key) => {
    if (key.includes('.')) {
      let value = data;
      key.split('.').forEach((segment) => {
        if (value) {
          value = value[segment];
        } else {
          return undefined;
        }
      });
      return value;
    } else {
      return data[key];
    }
  };

  return (
    <TableContainer style={{ marginTop: '4px' }} component={Paper}>
      <Table aria-label="simple table" size="medium">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.id} className={styles.columnCell}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={getValueForCell(row, rowKey)}
              onClick={() => (rowClicker ? rowClicker.clickHandler(row) : '')}
            >
              {columns.map((column) => {
                const rawValue = getValueForCell(row, column.id);
                let value = '-dff';
                try {
                  switch (column.type) {
                    case 'text':
                      value = String(rawValue ?? '-');
                      break;
                    case 'floatQuantity':
                      value = isNaN(rawValue) ? rawValue : rawValue.toFixed(3);
                      break;
                    case 'floatAmount':
                      value =
                        !rawValue || isNaN(rawValue)
                          ? String(rawValue ?? '-')
                          : rawValue.toFixed(2);
                      break;
                    case 'callback':
                      value = column.viewRender(row);
                      break;
                    case 'rowClick':
                      break;
                    case 'button':
                      value = (
                        <Button
                          onClick={() => column.clickHandler(row)}
                          variant="contained"
                          size="small"
                          color="secondary"
                        >
                          {column.title || 'Button'}
                        </Button>
                      );
                      break;
                    case 'image':
                      value = (
                        <CardMedia
                          size="small"
                          image={row[column.id]}
                          className={styles.image}
                        />
                      );
                      break;
                    default:
                      value = `invalid-type '${column.type}'`;
                  }
                } catch (error) {
                  value = error.message;
                  console.error(error);
                }
                return (
                  <TableCell
                    key={column.id}
                    style={
                      column.type === 'button' ? { maxWidth: '10px' } : null
                    }
                  >
                    {value}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
          {props.summary && (
            <TableRow>
              {columns.map((column) => {
                const value = props.summary[column.id];
                return (
                  <TableCell key={column.id}>
                    <b>{value ?? ''}</b>
                  </TableCell>
                );
              })}
            </TableRow>
          )}
          {props.children}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
