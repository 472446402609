import React, { useState, useEffect, useContext, useCallback } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import styles from './styles.module.css';
import DataTable from '../../utils/DataTable';
import StockDamageService from '../../../services/StockDamageService';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import Loader from '../../utils/Loading';
import ConfirmPopup from '../../utils/Alert/ConfirmPopup';
import Info from '../../utils/Alert/Info';
import Error from '../../utils/Alert/Error';
import Success from '../../utils/Alert/Success';
import RestrictionInfo from '../../utils/Alert/RestrictionInfo';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { StockDamageAlertContext } from '../../../Context/StockDamageAlertContext';
import { USER_PERMISSIONS, ROUTES, SUBSCRIPTION_TYPE } from '../../../const';
import { AlertSetter } from '../../utils/Alert/AlertSetter';
import { UserContext } from '../../../Context/UserContext';
import { ShopContext } from '../../../Context/ShopContext';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import DateTimeHelpers from '../../../helpers/DateTimeHelpers';
import { saveAs } from 'file-saver';

function StockDamageHistory() {
  const { user } = useContext(UserContext);
  const { stockDamageMsg } = useContext(StockDamageAlertContext);
  const { shop } = useContext(ShopContext);

  const [filterFromDate, setFilterFromDate] = useState(new Date());
  const [filterToDate, setFilterToDate] = useState(new Date());

  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [deleteData, setDeleteData] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const [successMsg, setSuccessMsg] = useState();

  const subscriptionType = shop?.subscription?.type;
  const userHasDamageWritePermission =
    user?.shop_permissions?.damage_permission === USER_PERMISSIONS.WRITE;

  const handleDeleteClick = (data) => setDeleteData(data);

  const handleDltStockDamage = async (row) => {
    setLoadingIndicator(true);
    try {
      await StockDamageService.deleteStockDamageHistory(row.id);
      loadStockDamageHistory().then();
      AlertSetter(setSuccessMsg, 'Damage deleted successfully');
    } catch (err) {
      setErrorMsg(err.message);
    }
    setLoadingIndicator(false);
  };

  const _getLoadDamageHistoryRequestParams = useCallback(() => {
    return {
      date_start: DateTimeHelpers.convertDateToIsoDMY(filterFromDate),
      date_end: DateTimeHelpers.convertDateToIsoDMY(filterToDate),
    };
  }, [filterFromDate, filterToDate]);

  const loadStockDamageHistory = useCallback(async () => {
    setLoadingIndicator(true);
    try {
      const params = _getLoadDamageHistoryRequestParams();
      const resp = await StockDamageService.getStockDamageHistory(params);
      setData(resp);
    } catch (err) {
      setErrorMsg(err.message);
    }
    setLoadingIndicator(false);
  }, [_getLoadDamageHistoryRequestParams]);

  const toExcelBtnPressed = async () => {
    try {
      const params = _getLoadDamageHistoryRequestParams();
      const res = await StockDamageService.getStockDamageHistoryExcel(params);
      const blob = new Blob([res.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, `stock-damage-${params.date_start}-${params.date_end}.xlsx`);
      AlertSetter(setSuccessMsg, 'Exported to excel successfully');
    } catch (err) {
      console.error(err);
      AlertSetter(setErrorMsg, err.message || 'Error export excel');
    }
  };

  const _getFilteredDamages = (damages, searchKey) => {
    if (!searchKey) {
      return damages;
    }

    return damages.filter(
      (dta) =>
        dta.item.name.toLowerCase().includes(searchKey.toLowerCase()) ||
        dta.date.includes(searchKey.toLowerCase()) ||
        dta.item.plu.toString().includes(searchKey.toLowerCase())
    );
  };

  const _getDamagesSummary = (damages) => {
    let totalQuantity = 0;
    let totalAmount = 0;
    damages.forEach((damage) => {
      totalQuantity += damage.quantity;
      totalAmount += damage.quantity * damage.item.cost;
    });

    return {
      totalQuantity: totalQuantity.toFixed(2),
      totalAmount: totalAmount.toFixed(2),
    };
  };

  useEffect(() => {
    if (subscriptionType && subscriptionType !== SUBSCRIPTION_TYPE.BASIC) {
      loadStockDamageHistory().then();
    }
  }, [subscriptionType, filterFromDate, filterToDate, loadStockDamageHistory]);

  const headerData = [
    {
      label: 'Date',
      id: 'date',
      type: 'text',
    },
    {
      label: 'PLU',
      id: 'item.plu',
      type: 'text',
    },
    {
      label: 'Name',
      id: 'item.name',
      type: 'text',
    },
    {
      label: 'Cost',
      id: 'item.cost',
      type: 'text',
    },
    {
      label: 'Quantity',
      id: 'quantity',
      type: 'callback',
      viewRender: (item) => {
        return `${item.quantity} ${item.item.unit || ''}`;
      },
    },
    {
      label: 'Total',
      id: 'total',
      type: 'callback',
      viewRender: (item) => {
        return item.quantity * item.item.cost;
      },
    },
  ];
  if (userHasDamageWritePermission) {
    headerData.push({
      label: 'Delete',
      id: 'deleteBtn',
      type: 'button',
      title: 'Delete',
      clickHandler: handleDeleteClick,
    });
  }

  const filteredDamages = _getFilteredDamages(data, searchText);
  const summary = _getDamagesSummary(filteredDamages);
  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loadingIndicator} />
      <ConfirmPopup
        data={deleteData}
        handleClose={handleDeleteClick}
        handleOkay={handleDltStockDamage}
        successBtnName={'Delete'}
        alertTitle={'Confirm Delete'}
        alertContent={
          "Deleted Stock Damage records can't be restored, do you want to delete the Stock Damage record? "
        }
      />
      <div className={styles.titleSec}>
        <h2 className={styles.title}>
          Stock Damage<span className={styles.menuTitle}>Management</span>
        </h2>
        {subscriptionType && subscriptionType !== SUBSCRIPTION_TYPE.BASIC && (
          <div style={{ justifyContent: 'flex-end' }}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>Export As</label>
            </div>
            <Button
              variant="contained"
              color="primary"
              className={styles.actionBtn}
              style={{ backgroundColor: '#d81b60', marginRight: 5 }}
              onClick={toExcelBtnPressed}
            >
              <ImportExportIcon className={styles.actionBtnIcon} />
              Excel
            </Button>
          </div>
        )}
      </div>

      {subscriptionType && subscriptionType === SUBSCRIPTION_TYPE.BASIC && (
        <RestrictionInfo
          title={'Feature not available '}
          content={
            'To get stock damage options upgrade subscription to premium'
          }
        />
      )}
      {subscriptionType && subscriptionType !== SUBSCRIPTION_TYPE.BASIC && (
        <>
          <div className={styles.changeable}>
            <div className={styles.filterSec}>
              <div className={styles.headTitle}>
                <h2 className={styles.subTitle}>Damage History</h2>
              </div>
              <div className={styles.filerInputSec}>
                {userHasDamageWritePermission && (
                  <Link
                    to={ROUTES.NEW_DAMAGE}
                    style={{ textDecorationColor: 'transparent' }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      className={styles.actionBtn}
                      style={{ backgroundColor: '#ff851b' }}
                    >
                      <NoteAddIcon className={styles.actionBtnIcon} />
                      New Damage
                    </Button>
                  </Link>
                )}
                <div className={styles.searchSec}>
                  <input
                    type="text"
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    className={styles.searchInput}
                    placeholder="search items"
                  />
                  <SearchIcon className={styles.searchIcon} />
                </div>
              </div>
            </div>
            <div className={styles.actionButtons}>
              <div className={styles.filterDiv}>
                <div style={{ paddingBottom: '4px' }}>
                  <label className={styles.label}>From</label>
                </div>
                <div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableFuture
                      variant="outlined"
                      format="dd/MM/yyyy"
                      id="date-picker-from"
                      className={styles.dateBox}
                      value={filterFromDate}
                      onChange={(date) => {
                        setFilterFromDate(date);
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'Change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div className={styles.filterDiv}>
                <div style={{ paddingBottom: '4px' }}>
                  <label className={styles.label}>To</label>
                </div>
                <div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableFuture
                      variant="outlined"
                      format="dd/MM/yyyy"
                      id="date-picker-to"
                      className={styles.dateBox}
                      value={filterToDate}
                      onChange={(date) => {
                        setFilterToDate(date);
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'Change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            </div>
          </div>

          {(errorMsg || stockDamageMsg.error) && (
            <div className={styles.marginTop}>
              <Error title={errorMsg || stockDamageMsg.error} />
            </div>
          )}
          {(stockDamageMsg?.success || successMsg) && (
            <div className={styles.marginTop}>
              <Success title={stockDamageMsg.success || successMsg} />
            </div>
          )}
          {data &&
            (filteredDamages && filteredDamages.length ? (
              <DataTable
                columns={headerData}
                rows={filteredDamages}
                summary={{
                  'item.name': 'Total',
                  quantity: summary.totalQuantity,
                  total: summary.totalAmount,
                }}
              />
            ) : (
              <Info
                title={'You have no damage to list'}
                content={
                  "You haven't any damages to list with given properties"
                }
              />
            ))}
        </>
      )}
    </div>
  );
}

export default withConsoleBase(StockDamageHistory);
