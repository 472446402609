import BackendService from './BackendService';

async function getCustomers() {
  return BackendService.getData('/customers/');
}
async function createCustomer(data) {
  return BackendService.postData('/customers/', data);
}
async function updateCustomer(id, data = {}) {
  return BackendService.put(`/customer/${id}/`, data);
}
async function deleteCustomer(id) {
  return BackendService.destroy(`/customer/${id}/`, '');
}
async function deleteCustomerPurchase(data) {
  return BackendService.destroy(`/sales/customer/`, data);
}
async function assignCustomer(data) {
  return BackendService.postData('/sales/customer/', data);
}
async function getLastFourBill(id) {
  return BackendService.getData(`/sales/customer/?customer_id=${id}`);
}
const CustomerService = {
  getCustomers,
  createCustomer,
  updateCustomer,
  deleteCustomer,
  assignCustomer,
  getLastFourBill,
  deleteCustomerPurchase,
};

export default CustomerService;
