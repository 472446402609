import styles from './styles.module.css';
import CloseIcon from '@material-ui/icons/Close';
import DataTable from '../utils/DataTable';
import Info from '../utils/Alert/Info';

export default function ListDialog(props) {
  const {
    toggleItemsTab,
    rowData,
    tableHeader,
    title,
    emptyDataTitle,
    emptyDataContent,
  } = props;
  return (
    <div className={styles.popUp}>
      <div className={styles.contentWrapper}>
        <div className={styles.headSec}>
          <h2 className={styles.editTitle}>{title}</h2>
          <CloseIcon
            onClick={() => {
              toggleItemsTab('');
            }}
          />
        </div>
        <div className={styles.tableContent}>
          {rowData.length && rowData ? (
            <DataTable columns={tableHeader} rows={rowData} />
          ) : (
            <Info title={emptyDataTitle} content={emptyDataContent} />
          )}
        </div>
      </div>
    </div>
  );
}
