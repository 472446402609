import React, { useState, useEffect, useContext } from 'react';
import { Button } from '@material-ui/core';
import styles from './styles.module.css';
import DataTable from '../../utils/DataTable/';
import CreateDevicesDialog from '../../popups/CreateDeviceDialog';
import CreateIcon from '@material-ui/icons/Create';
import RefreshIcon from '@material-ui/icons/Refresh';
import DeviceService from '../../../services/DeviceService';
import Loader from '../../utils/Loading';
import setDelay from '../../../helpers/LoadingDelay';
import ConfirmPopup from '../../utils/Alert/ConfirmPopup';
import Info from '../../utils/Alert/Info';
import Error from '../../utils/Alert/Error';
import Success from '../../utils/Alert/Success';
import { AlertSetter } from '../../utils/Alert/AlertSetter';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import { UserContext } from '../../../Context/UserContext';
import { USER_PERMISSIONS } from '../../../const';

function Devices() {
  const { user } = useContext(UserContext);
  const [devices, setDevices] = useState('');
  const [addTab, setAddTab] = useState('');
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [deleteData, setDeleteData] = useState('');
  const [errorMsg, setErrorMsg] = useState();
  const [successMsg, setSuccessMsg] = useState();

  const getDevices = async () => {
    setLoadingIndicator(true);
    try {
      const res = await DeviceService.getDevices();
      setDevices(
        res.map((device) => ({
          ...device,
          connection: device.connected_to
            ? 'online'
            : device.disconnected_at
            ? 'Disconnected'
            : 'N/A',
        }))
      );
    } catch (err) {
      AlertSetter(setErrorMsg, err.message);
    }

    setDelay(setLoadingIndicator);
  };
  const handleDeleteClick = (data) => {
    setDeleteData(data);
  };
  const handleDeviceDeletion = async (row) => {
    try {
      await DeviceService.deleteDevice(row.id);
      getDevices();
      AlertSetter(setSuccessMsg, 'Device deleted successfully');
    } catch (err) {
      AlertSetter(setErrorMsg, err.message);
    }
  };
  useEffect(() => {
    getDevices();
  }, []);
  const headerData = [
    {
      label: 'Machine ID',
      id: 'id',
      type: 'text',
    },
    {
      label: 'Mac ID',
      id: 'mac_id',
      type: 'text',
    },
    {
      label: 'Machine Name',
      id: 'machine_name',
      type: 'text',
    },
    {
      label: 'Machine Number',
      id: 'machine_number',
      type: 'text',
    },
    {
      label: 'Machine Status',
      id: 'connection',
      type: 'text',
    },
  ];
  if (
    ((user || {}).shop_permissions || {}).devices_permission ===
    USER_PERMISSIONS.WRITE
  ) {
    headerData.push({
      label: 'Delete',
      title: 'Delete',
      id: 'delet',
      type: 'button',
      clickHandler: handleDeleteClick,
    });
  }
  const handleRefresh = () => {
    getDevices();
  };
  const toggleAddTab = () => {
    addTab ? setAddTab('') : setAddTab('open');
  };
  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loadingIndicator} />
      <ConfirmPopup
        data={deleteData}
        handleClose={handleDeleteClick}
        handleOkay={handleDeviceDeletion}
        alertTitle={'Confirm Delete'}
        successBtnName={'Delete'}
        alertContent={
          "Deleted device can't be restored, do you want to delete the device?"
        }
      />
      <div className={styles.titleSec}>
        <h2 className={styles.title}>
          Devices <span className={styles.menuTitle}>list</span>
        </h2>
      </div>
      <div className={styles.changeable}>
        <div className={styles.filterSec}>
          <div className={styles.headTitle}>
            <h2 className={styles.subTitle}>Devices</h2>
          </div>
          <div className={styles.actionButtons}>
            {((user || {}).shop_permissions || {}).devices_permission ===
            USER_PERMISSIONS.READ ? (
              ''
            ) : (
              <Button
                variant="contained"
                color="primary"
                className={styles.actionBtn}
                size="small"
                style={{ backgroundColor: '#605ca8' }}
                onClick={toggleAddTab}
              >
                <CreateIcon />
                Connect to devices
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={styles.actionBtn}
              style={{ backgroundColor: '#00a65a' }}
              onClick={handleRefresh}
            >
              <RefreshIcon />
              Refresh devices status
            </Button>
          </div>
        </div>
      </div>
      {/* <DataTable
        columns={headerData}
        rows={devices}
        handleDeviceDeletion={handleDeviceDeletion}
      /> */}
      {errorMsg && (
        <div className={styles.marginTop}>
          <Error title={errorMsg} />
        </div>
      )}
      {successMsg && (
        <div className={styles.marginTop}>
          <Success title={successMsg} />
        </div>
      )}

      {!loadingIndicator &&
        (devices[0] ? (
          <DataTable
            columns={headerData}
            rows={devices}
            handleDeviceDeletion={handleDeviceDeletion}
          />
        ) : (
          <Info
            title={'You have no device to list'}
            content={"You haven't  any device in this shop id"}
          />
        ))}
      {addTab && (
        <CreateDevicesDialog
          toggleAddTab={toggleAddTab}
          setErrorMsg={setErrorMsg}
          setSuccessMsg={setSuccessMsg}
        />
      )}
    </div>
  );
}

export default withConsoleBase(Devices);
