import React, { useState, useEffect, useContext } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { TextField, MenuItem, Button } from '@material-ui/core';
import styles from './styles.module.css';
import DataTable from '../../utils/DataTable';
import ItemSummaryReportService from '../../../services/ItemSummaryReportService';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { saveAs } from 'file-saver';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import DateTimeHelpers from '../../../helpers/DateTimeHelpers';
import ItemService from '../../../services/ItemService';
import DeviceService from '../../../services/DeviceService';
import Info from '../../utils/Alert/Info';
import Loader from '../../utils/Loading';
import setDelay from '../../../helpers/LoadingDelay';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import { AlertSetter } from '../../utils/Alert/AlertSetter';
import Success from '../../utils/Alert/Success';
import Error from '../../utils/Alert/Error';
import { ShopContext } from '../../../Context/ShopContext';
import { SUBSCRIPTION_TYPE } from '../../../const';

function ItemSummaryReport() {
  const { shop } = useContext(ShopContext);

  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [filterFromDate, setFilterFromDate] = useState(new Date());
  const [filterToDate, setFilterToDate] = useState(new Date());
  const [category, setCategory] = useState('all');
  const [gst, setGst] = useState('all');
  const [items, setItems] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [currency, setCurrency] = useState('');
  const [categories, setCategories] = useState('');
  const [vats, setVats] = useState('');
  const [machineName, setMachineName] = useState('all');
  const [machineNames, setMachineNames] = useState('');
  const [errorMsg, setErrorMsg] = useState();
  const [successMsg, setSuccessMsg] = useState();

  const loadItemSummaryReports = async (
    from = filterFromDate,
    to = filterToDate,
    categ = category,
    mac = machineName,
    vat = gst
  ) => {
    setLoadingIndicator(true);
    try {
      const data = {
        date: DateTimeHelpers.convertDateToDMY(from),
        to_date: DateTimeHelpers.convertDateToDMY(to),
        category_id: categ === 'all' ? '' : categ,
        filter_mac: mac === 'all' ? '' : [mac],
        filter_vat: vat === 'all' ? '' : [vat],
      };
      const resp = await ItemSummaryReportService.getItemSummaryReport(data);
      setItems(resp.sales_items);
      setDelay(setLoadingIndicator);
      setCurrency(resp.currency);
    } catch (err) {
      AlertSetter(setErrorMsg, err.message);
    }
    setLoadingIndicator(false);
  };
  const getCategories = async () => {
    const res = await ItemService.getItemCategories();
    setCategories(res);
  };
  const getMachineName = async () => {
    const res = await DeviceService.getDevices();
    setMachineNames(res);
  };
  const getVat = async () => {
    const res = await ItemSummaryReportService.getVat();
    setVats(res);
  };
  useEffect(() => {
    loadItemSummaryReports(filterFromDate, filterToDate);
    getCategories();
    getMachineName();
    getVat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toExcelBtnPressed = async (e) => {
    try {
      const data = {
        date: DateTimeHelpers.convertDateToDMY(filterFromDate),
        to_date: DateTimeHelpers.convertDateToDMY(filterToDate),
        target: 'excel',
      };
      const res = await ItemSummaryReportService.getItemSummaryExcel(data);
      const blob = new Blob([res.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, `item-summary-${data.date}-${data.to_date}.xlsx`);
      AlertSetter(setSuccessMsg, 'Exported to excel successfully');
    } catch (err) {
      AlertSetter(setErrorMsg, err.message);
    }
  };

  const toCsvBtnPressed = async (e) => {
    try {
      const data = {
        date: DateTimeHelpers.convertDateToDMY(filterFromDate),
        to_date: DateTimeHelpers.convertDateToDMY(filterToDate),
        target: 'csv',
      };
      const res = await ItemSummaryReportService.getItemSummaryCsv(data);
      const blob = new Blob([res.data], {
        type: 'text/csv',
      });
      saveAs(blob, `item-summary-${data.date}-${data.to_date}.csv`);
      AlertSetter(setSuccessMsg, 'Exported to CSV successfully');
    } catch (err) {
      AlertSetter(setErrorMsg, err.message);
    }
  };

  const _getItemsFiltered = (items, searchText) => {
    if (!searchText) {
      return items;
    }

    return items.filter(
      (item) =>
        item.id.toString().includes(searchText.toLowerCase()) ||
        item.name.toLowerCase().includes(searchText.toLowerCase())
    );
  };

  const _getItemsReportSummary = (items) => {
    let totalExpence = 0;
    let totalAmount = 0;
    let totalProfit = 0;
    items.forEach((item) => {
      totalExpence += item.purchase_expense;
      totalAmount += item.total;
      totalProfit += item.profit;
    });

    return {
      totalExpence: totalExpence.toFixed(2),
      totalProfit: totalProfit.toFixed(2),
      totalAmount: totalAmount.toFixed(2),
    };
  };

  const subscriptionType = shop?.subscription?.type || '';
  const headerData = [
    {
      label: 'PLU',
      id: 'id',
      type: 'text',
    },
    {
      label: 'Item Name',
      id: 'name',
      type: 'text',
    },
    {
      label: 'Unit Price',
      id: 'price',
      type: 'floatAmount',
    },
    {
      label: 'GST(%)',
      id: 'vat',
      type: 'text',
    },
    {
      label: 'GST Amount',
      id: 'vat_amount',
      type: 'floatAmount',
    },
    {
      label: 'Sold Quantity',
      id: 'sold_quantity',
      type: 'callback',
      viewRender: (item) => {
        if (item.sold_quantity === '' || isNaN(item.sold_quantity)) {
          return item.sold_quantity;
        }
        return `${item.sold_quantity.toFixed(2)} ${item.unit || ''}`;
      },
    },
  ];
  if (subscriptionType && subscriptionType === SUBSCRIPTION_TYPE.PREMIUM) {
    headerData.push({
      label: 'Purchase Rate',
      id: 'purchase_rate',
      type: 'callback',
      viewRender: (item) => {
        if (item.purchase_rate === '' || isNaN(item.purchase_rate)) {
          return item.purchase_rate;
        }
        return `${currency || ''} ${item.purchase_rate.toFixed(2)}`;
      },
    });
    headerData.push({
      label: 'Purchase Tax',
      id: 'purchase_tax',
      type: 'callback',
      viewRender: (item) => {
        if (item.purchase_tax === '' || isNaN(item.purchase_tax)) {
          return item.purchase_tax;
        }
        return `${currency || ''} ${item.purchase_tax.toFixed(2)}`;
      },
    });
    headerData.push({
      label: 'Expense',
      id: 'purchase_expense',
      type: 'callback',
      viewRender: (item) => {
        if (item.purchase_expense === '' || isNaN(item.purchase_expense)) {
          return item.purchase_expense;
        }
        return `${currency || ''} ${item.purchase_expense.toFixed(2)}`;
      },
    });
    headerData.push({
      label: 'Damage',
      id: 'damage_value',
      type: 'callback',
      viewRender: (item) => {
        if (item.damage_value === '' || isNaN(item.damage_value)) {
          return item.damage_value;
        }
        return `${currency || ''} ${item.damage_value.toFixed(2)}`;
      },
    });
    headerData.push({
      label: 'Profit*',
      id: 'profit',
      type: 'callback',
      viewRender: (item) => {
        if (item.profit === '' || isNaN(item.profit)) {
          return item.profit;
        }
        return `${currency || ''} ${item.profit.toFixed(2)}`;
      },
    });
  }
  headerData.push({
    label: 'Total Amount',
    id: 'total',
    type: 'callback',
    viewRender: (item) => {
      return `${currency || ''} ${item.total}`;
    },
  });

  const filteredItems = _getItemsFiltered(items, searchText);
  const summary = _getItemsReportSummary(filteredItems);
  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loadingIndicator} />

      <div className={styles.titleSec}>
        <span className={styles.title}>
          {' '}
          Reports<span className={styles.menuTitle}>Generation</span>
        </span>
        <div style={{ justifyContent: 'flex-end' }}>
          <div style={{ paddingBottom: '4px' }}>
            <label className={styles.label}>Export As</label>
          </div>
          <Button
            variant="contained"
            color="primary"
            className={styles.actionBtn}
            style={{ backgroundColor: '#d81b60', marginRight: 5 }}
            onClick={toExcelBtnPressed}
          >
            <ImportExportIcon className={styles.actionBtnIcon} />
            Excel
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={styles.actionBtn}
            style={{ backgroundColor: '#00a65a' }}
            onClick={toCsvBtnPressed}
          >
            <ImportExportIcon className={styles.actionBtnIcon} />
            CSV
          </Button>
        </div>
      </div>
      <div className={styles.changeable}>
        <div className={styles.filterSec}>
          <div className={styles.headTitle}>
            <h2 className={styles.subTitle}>
              Item Summary Report of{' '}
              {DateTimeHelpers.convertDateToDMY(filterFromDate)}
              {'  '}to{'  '}
              {DateTimeHelpers.convertDateToDMY(filterToDate)}
            </h2>
          </div>
          <div className={styles.filerInputSec}>
            <div className={styles.searchSec}>
              <input
                type="text"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                className={styles.searchInput}
                placeholder="search items"
              />
              <SearchIcon className={styles.searchIcon} />
            </div>
          </div>
        </div>
        <div className={styles.actionButtons}>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>From</label>
            </div>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableFuture
                  variant="outlined"
                  format="dd/MM/yyyy"
                  id="date-picker-from"
                  className={styles.dateBox}
                  value={filterFromDate}
                  onChange={(date) => {
                    setFilterFromDate(date);
                    loadItemSummaryReports(date, filterToDate);
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'Change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>To</label>
            </div>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableFuture
                  variant="outlined"
                  format="dd/MM/yyyy"
                  id="date-picker-to"
                  className={styles.dateBox}
                  value={filterToDate}
                  onChange={(date) => {
                    setFilterToDate(date);
                    loadItemSummaryReports(filterFromDate, date);
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'Change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>Category</label>
            </div>
            <div>
              <TextField
                select
                name="gst"
                size="small"
                variant="outlined"
                defaultValue={category}
                color="primary"
                className={styles.textfeild}
                onChange={(event) => {
                  setCategory(event.target.value);
                  loadItemSummaryReports(
                    filterFromDate,
                    filterToDate,
                    event.target.value
                  );
                }}
              >
                {categories &&
                  categories.map((category) => (
                    <MenuItem value={category.id}>{category.name}</MenuItem>
                  ))}
                <MenuItem value="all">All</MenuItem>
              </TextField>
            </div>
          </div>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>Machine No/Name</label>
            </div>
            <div>
              <TextField
                select
                name="machineName"
                size="small"
                variant="outlined"
                defaultValue={machineName}
                color="primary"
                className={styles.textfeild}
                onChange={(event) => {
                  setMachineName(event.target.value);
                  loadItemSummaryReports(
                    filterFromDate,
                    filterToDate,
                    category,
                    event.target.value
                  );
                }}
              >
                {machineNames &&
                  machineNames.map((machine) => (
                    <MenuItem value={machine.machine_number}>
                      {`${machine.machine_number} - ${machine.machine_name}`}
                    </MenuItem>
                  ))}
                <MenuItem value="all">All</MenuItem>
              </TextField>
            </div>
          </div>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>GST</label>
            </div>
            <div>
              <TextField
                select
                name="category"
                size="small"
                variant="outlined"
                defaultValue={gst}
                color="primary"
                className={styles.textfeild}
                onChange={(event) => {
                  setGst(event.target.value);
                  loadItemSummaryReports(
                    filterFromDate,
                    filterToDate,
                    category,
                    machineName,
                    event.target.value
                  );
                }}
              >
                {vats &&
                  vats.map((vat) => <MenuItem value={vat}>{vat}</MenuItem>)}
                <MenuItem value="all">All</MenuItem>
              </TextField>
            </div>
          </div>
        </div>
      </div>
      {errorMsg && (
        <div className={styles.marginTop}>
          <Error title={errorMsg} />
        </div>
      )}
      {successMsg && (
        <div className={styles.marginTop}>
          <Success title={successMsg} />
        </div>
      )}

      {items &&
        (filteredItems && filteredItems.length ? (
          <DataTable
            columns={headerData}
            rows={filteredItems}
            rowKey="id"
            summary={{
              purchase_rate: 'Total',
              purchase_expense: `${currency} ${summary.totalExpence}`,
              profit: `${currency} ${summary.totalProfit}`,
              total: `${currency} ${summary.totalAmount}`,
            }}
          />
        ) : (
          <Info
            title={'You have no report to show for this date'}
            content={
              'Reports are generated based on the sales. Please verify your inputs to the generate report form.'
            }
          />
        ))}
    </div>
  );
}

export default withConsoleBase(ItemSummaryReport);
