import styles from './styles.module.css';
import React, { useState, useEffect, useContext } from 'react';
import { TextField, MenuItem } from '@material-ui/core';
import BillFormatService from '../../../services/BillFormatService';
import Loader from '../../utils/Loading';
import { ShopContext } from '../../../Context/ShopContext';
import RestrictionInfo from '../../utils/Alert/RestrictionInfo';
import Error from '../../utils/Alert/Error';
import Success from '../../utils/Alert/Success';
import { AlertSetter } from '../../utils/Alert/AlertSetter';
import Badge from '@mui/material/Badge';
import pos_sm from '../../../images/pos_sm.png';
import pos_big from '../../../images/pos_big.png';
import AssignKeyDialog from '../../popups/AssignKeyDialog';
import ItemService from '../../../services/ItemService';
import DeviceService from '../../../services/DeviceService';
import Info from '../../utils/Alert/Info';
import MachineHelper from '../../../helpers/MachineHelper';
import { SUBSCRIPTION_TYPE } from '../../../const';

export default function ShortKey() {
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const { shop } = useContext(ShopContext);
  const subscriptionType = shop?.subscription?.type;
  const [successMsg, setSuccessMsg] = useState();
  const [errMsg, setErrMsg] = useState();
  const [machines, setMachines] = useState();
  const [keys, setKeys] = useState();
  const [selectedMachine, setSelectedMachine] = useState();
  const [selectedDevice, setSelectedDevice] = useState(40);
  const [selectedKey, setSelectedKey] = useState();
  const [items, setItems] = useState();

  useEffect(() => {
    const getItems = async () => {
      const res = await ItemService.getItems();
      setItems(res);
    };
    getItems();
  }, []);

  const getAssignedKey = async () => {
    const res = await DeviceService.getDeviceShortKey(selectedMachine);
    setKeys(res);
  };

  useEffect(() => {
    const getAssignedKey = async () => {
      const res = await DeviceService.getDeviceShortKey(selectedMachine);
      setKeys(res);
    };
    if (selectedMachine) getAssignedKey();
  }, [selectedMachine]);

  const resetErrMsg = () => {
    setErrMsg(false);
    setSuccessMsg(false);
  };
  const handleUpadateKey = async (key, itemId) => {
    const data = {
      machine_number: selectedMachine,
      data: [
        {
          key,
          item: itemId,
        },
      ],
    };
    try {
      await DeviceService.updateShortKey(data);
      successDailouge();
      getAssignedKey();
    } catch (err) {
      FailedDialog();
    }
  };
  const successDailouge = async () => {
    resetErrMsg();
    AlertSetter(setSuccessMsg, 'Succesfully updated!');
  };

  const FailedDialog = async () => {
    resetErrMsg();
    AlertSetter(
      setErrMsg,
      'Oops! Something went wrong! Make sure you have entered all the details correctly!'
    );
  };

  useEffect(() => {
    getMachines();
    // eslint-disable-next-line
  }, []);

  const isThisKeyAssigned = (selectedKey) => {
    return keys.find((key) => key.key === selectedKey);
  };

  const getMachines = async (e) => {
    setLoadingIndicator(true);
    const resMachines = await BillFormatService.getMachines();
    setMachines(resMachines);
    setSelectedMachine(resMachines ? resMachines[0]?.machine_number : null);
    setLoadingIndicator(false);
  };

  const handleMachineChange = (event) => setSelectedMachine(event.target.value);
  const handleDeviceChange = (deviceType) => setSelectedDevice(deviceType);
  const handleKeyChange = (key, item = '') => setSelectedKey({ key, item });
  const closeKeyCombinationTab = () => setSelectedKey('');

  return (
    <>
      {subscriptionType && subscriptionType === SUBSCRIPTION_TYPE.BASIC && (
        <RestrictionInfo
          title={'Feature not available '}
          content={'To get short key options upgrade subscription to premium'}
        />
      )}
      <div className={styles.contentLayout}>
        {subscriptionType && subscriptionType !== SUBSCRIPTION_TYPE.BASIC && (
          <>
            <Loader isOpen={loadingIndicator} />
            <div className={styles.shorKeyContainer}>
              <div className={styles.selectMachineSection}>
                <div className={styles.machineSelectTitle}>Machine No/Name</div>
                {selectedMachine && (
                  <TextField
                    style={{ marginTop: '15px', marginBottom: '15px' }}
                    select
                    name="selectedMachine"
                    size="small"
                    variant="outlined"
                    color="primary"
                    fullWidth
                    value={selectedMachine}
                    onChange={handleMachineChange}
                  >
                    {machines?.map((machine) => (
                      <MenuItem key={machine.id} value={machine.machine_number}>
                        {MachineHelper.getMachineString(machine)}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </div>
              {errMsg && <Error title={errMsg} />}
              {successMsg && <Success title={successMsg} />}
              {!selectedMachine && (
                <Info
                  title={'You have no device to list'}
                  content={"You haven't  any device in this shop id"}
                />
              )}
              {selectedMachine && (
                <>
                  <div className={styles.machineTypes}>
                    <Badge badgeContent={'40 keys'} color="primary">
                      <div
                        className={`${styles.machineWrapper} ${
                          selectedDevice === 40 ? styles.selectedMachine : null
                        }`}
                        onClick={() => handleDeviceChange(40)}
                      >
                        <img
                          src={pos_big}
                          className={styles.machineImage}
                          alt="POS Machine"
                        />
                        <span className={styles.machineName}>POS</span>
                      </div>
                    </Badge>
                    <Badge badgeContent={'30 keys'} color="primary">
                      <div
                        className={`${styles.machineWrapper} ${
                          selectedDevice === 30 ? styles.selectedMachine : null
                        }`}
                        onClick={() => handleDeviceChange(30)}
                      >
                        <img
                          src={pos_sm}
                          className={styles.machineImage}
                          alt="ECR Machine"
                        />
                        <span className={styles.machineName}>ECR</span>
                      </div>
                    </Badge>
                  </div>
                  <div className={styles.keyListContainer}>
                    {keys &&
                      Array.from(
                        { length: selectedDevice - 1 },
                        (_, i) => i + 1
                      ).map((key) => {
                        const keyObj = isThisKeyAssigned(key);
                        return keyObj ? (
                          <div
                            className={styles.keyItem}
                            onClick={() => handleKeyChange(key, keyObj.item)}
                          >
                            <span className={styles.keyName}>{key}</span>
                            <span className={styles.keyStatus}>
                              {keyObj.item.plu}
                            </span>
                          </div>
                        ) : (
                          <Badge badgeContent={'not set'} color="primary">
                            <div
                              className={styles.keyItem}
                              onClick={() => handleKeyChange(key)}
                            >
                              <span className={styles.keyName}>{key}</span>
                              <span className={styles.keyStatus}>none</span>
                            </div>
                          </Badge>
                        );
                      })}
                    <div className={styles.keyItemDisabled}>
                      <span className={styles.keyName}>{selectedDevice}</span>
                      <span className={styles.keyStatus}>Reserved</span>
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )}
        {selectedKey && (
          <AssignKeyDialog
            closeTab={closeKeyCombinationTab}
            keyData={selectedKey.key}
            selectedItem={selectedKey.item}
            items={items}
            keyUpadateHandler={handleUpadateKey}
          />
        )}
      </div>
    </>
  );
}
