import { createContext, useState } from 'react';
export const StockDamageAlertContext = createContext();

export default function StockDamageAlertProvider(props) {
  const [stockDamageMsg, setStockDamageMsg] = useState({
    error: '',
    success: '',
  });

  return (
    <StockDamageAlertContext.Provider value={{ stockDamageMsg, setStockDamageMsg }}>
      {props.children}
    </StockDamageAlertContext.Provider>
  );
}
