import { Grid, TextField, Button } from '@material-ui/core';
import React, { useState } from 'react';
import styles from './styles.module.css';
import CloseIcon from '@material-ui/icons/Close';
import UserService from '../../services/UserService';
import { AlertSetter } from '../utils/Alert/AlertSetter';
import ConfirmPopup from '../utils/Alert/ConfirmPopup';
import { DialogActions } from '@mui/material';

export default function NewUserDialog(props) {
  const { toggleAddTab, setSuccessMsg } = props;
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [first_name, setFirstname] = useState('');
  const [last_name, setLastname] = useState('');
  const [confirm_add_existing_user, setExistingUser] = useState(false);

  const createNewUser = async () => {
    const data = {
      username,
      password,
      first_name,
      last_name,
      confirm_add_existing_user,
    };
    try {
      await UserService.createNewUser(data);
      AlertSetter(setSuccessMsg, 'User created successfully');
      toggleAddTab('save');
    } catch (err) {
      setExistingUser(true);
    }
  };

  const handleConfirmClick = (data) => setExistingUser(data);

  return (
    <div className={styles.popUp}>
      <div className={styles.contentWrapper}>
        <ConfirmPopup
          data={confirm_add_existing_user}
          handleClose={handleConfirmClick}
          handleOkay={createNewUser}
          alertTitle={'Confirm Add User'}
          successBtnName={'Yes'}
          alertContent={
            'There is a user with the provided username already, do you wanted to add the user? Otherwise change username and try again.'
          }
        />
        <div className={styles.headSec}>
          <h2 className={styles.editTitle}>Add New User</h2>
          <CloseIcon onClick={toggleAddTab} />
        </div>
        <div className={styles.inputSec}>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid
              item
              xs={4}
              sm={4}
              spacing={2}
              justify="flex-end"
              className={styles.inputLabelContainer}
            >
              <h3 className={styles.inputLabel}>Username</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Username"
                variant="outlined"
                size="small"
                name="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid
              item
              xs={4}
              sm={4}
              spacing={2}
              justify="flex-end"
              className={styles.inputLabelContainer}
            >
              <h3 className={styles.inputLabel}>Password</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Password"
                variant="outlined"
                size="small"
                type="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid
              item
              xs={4}
              sm={4}
              spacing={2}
              justify="flex-end"
              className={styles.inputLabelContainer}
            >
              <h3 className={styles.inputLabel}>First Name</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="First Name"
                variant="outlined"
                size="small"
                name="first_name"
                value={first_name}
                onChange={(e) => setFirstname(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid
              item
              xs={4}
              sm={4}
              spacing={2}
              justify="flex-end"
              className={styles.inputLabelContainer}
            >
              <h3 className={styles.inputLabel}>Last Name</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Last Name"
                variant="outlined"
                size="small"
                name="last_name"
                value={last_name}
                onChange={(e) => setLastname(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={toggleAddTab}
              className={styles.closeBtn}
            >
              close
            </Button>
            <Button
              variant="contained"
              size="small"
              color="primary"
              style={{ backgroundColor: '#00a65a' }}
              disabled={
                username === '' ||
                password === '' ||
                first_name === '' ||
                last_name === ''
              }
              onClick={createNewUser}
            >
              Create User
            </Button>
          </DialogActions>
        </div>
      </div>
    </div>
  );
}
