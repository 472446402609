import React, { useState, useEffect, useContext, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import SearchIcon from '@material-ui/icons/Search';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import RestrictionInfo from '../../utils/Alert/RestrictionInfo';
import ExpenseFormDialog from '../../popups/ExpenseFormDialog';
import ExpenseService from '../../../services/ExpenseService';
import { AlertSetter } from '../../utils/Alert/AlertSetter';
import { ShopContext } from '../../../Context/ShopContext';
import { UserContext } from '../../../Context/UserContext';
import ConfirmPopup from '../../utils/Alert/ConfirmPopup';
import setDelay from '../../../helpers/LoadingDelay';
import Success from '../../utils/Alert/Success';
import DataTable from '../../utils/DataTable/';
import Error from '../../utils/Alert/Error';
import Info from '../../utils/Alert/Info';
import Loader from '../../utils/Loading';
import styles from './styles.module.css';
import { USER_PERMISSIONS, SUBSCRIPTION_TYPE } from '../../../const';
import DateTimeHelpers from '../../../helpers/DateTimeHelpers';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { saveAs } from 'file-saver';
import CsvHelper from '../../../helpers/CsvHelper';

function Expenses() {
  const { shop } = useContext(ShopContext);
  const { user } = useContext(UserContext);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [expenses, setExpenses] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showExpenseFormDialog, setShowExpenseFormDialog] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const [successMsg, setSuccessMsg] = useState();

  const subscriptionType = shop?.subscription?.type;
  const userHasWritePermission =
    user?.shop_permissions?.expense_permission === USER_PERMISSIONS.WRITE;

  const getExpenses = useCallback(
    async (params = {}) => {
      if (!params.search && searchText) {
        params.search = searchText;
      }
      if (!params.start_date && startDate) {
        params.start_date = DateTimeHelpers.getDateYMD(startDate);
      }
      if (!params.end_date && endDate) {
        params.end_date = DateTimeHelpers.getDateYMD(endDate);
      }
      setLoadingIndicator(true);
      const res = await ExpenseService.getExpenses(params);
      setExpenses(res);
      setDelay(setLoadingIndicator);
    },
    [setExpenses, searchText, startDate, endDate]
  );

  const handleDateChange = async (_startDate, _endDate) => {
    setLoadingIndicator(true);
    setStartDate(_startDate);
    setEndDate(_endDate);
    const params = {
      start_date: DateTimeHelpers.getDateYMD(_startDate),
      end_date: DateTimeHelpers.getDateYMD(_endDate),
    };
    const res = await ExpenseService.getExpenses(params);
    setExpenses(res);
    setLoadingIndicator(false);
  };

  const handleExportToExcel = async () => {
    const startDateStr = DateTimeHelpers.getDateYMD(startDate);
    const endDateStr = DateTimeHelpers.getDateYMD(endDate);

    const csv = CsvHelper.getString(expenses, {
      id: 'ID',
      date: 'Date',
      description: 'Description',
      amount: 'Amount',
    });
    const blob = new Blob([csv], {
      type: 'text/csv',
    });
    saveAs(blob, `expenses-${startDateStr}-${endDateStr}.csv`);
    AlertSetter(setSuccessMsg, 'Exported to excel successfully');
  };

  const _getExpenseAmountSum = (expenses) => {
    if (!expenses || !expenses.length) {
      return 0;
    }

    let sum = 0;
    expenses.forEach((expenses) => (sum += expenses.amount));
    return sum.toFixed(2);
  };

  const handleDeleteClick = (data) => {
    setSelectedExpense(data);
    setShowDeleteConfirm(true);
  };

  const handleDeleteCancel = (data) => {
    setSelectedExpense(null);
    setShowDeleteConfirm(false);
  };

  const handleDeleteExpense = async () => {
    setLoadingIndicator(true);
    try {
      await ExpenseService.deleteExpense(selectedExpense.id);
      getExpenses().then();
      AlertSetter(setSuccessMsg, 'Expense deleted successufully');
    } catch (err) {
      AlertSetter(setErrorMsg, err.message);
    }
    setDelay(setLoadingIndicator);
  };

  const handleNewExpenseClick = () => {
    setShowExpenseFormDialog(true);
  };

  const handleEditClick = (expense) => {
    setSelectedExpense(expense);
    setShowExpenseFormDialog(true);
  };

  const handleFormCancel = () => {
    setShowExpenseFormDialog(false);
    setSelectedExpense(null);
  };

  const handleFormSuccess = () => {
    setShowExpenseFormDialog(false);
    getExpenses().then();
  };

  /// Obsolete methods
  const handleSearch = (value) => {
    setSearchText(value);
    getExpenses({ search: value }).then();
  };

  const headerData = [
    {
      label: 'ID',
      id: 'id',
      type: 'text',
    },
    {
      label: 'Date',
      id: 'date',
      type: 'text',
    },
    {
      label: 'Description',
      id: 'description',
      type: 'text',
    },
    {
      label: 'Type',
      id: 'type',
      type: 'callback',
      viewRender: (obj) => {
        return obj.amount >= 0 ? 'Expense' : 'Income';
      },
    },
    {
      label: 'Amount',
      id: 'amount',
      type: 'floatAmount',
    },
  ];
  if (userHasWritePermission) {
    headerData.push({
      label: 'Edit',
      id: 'updateItems',
      type: 'button',
      title: 'Edit',
      clickHandler: handleEditClick,
    });
    headerData.push({
      label: 'Delete',
      id: 'deleteBtn',
      type: 'button',
      title: 'Delete',
      clickHandler: handleDeleteClick,
    });
  }

  useEffect(() => {
    if (subscriptionType && subscriptionType !== SUBSCRIPTION_TYPE.BASIC) {
      getExpenses().then();
    }
  }, [subscriptionType, getExpenses]);

  const expenseAmountSum = _getExpenseAmountSum(expenses);
  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loadingIndicator} />
      <div className={styles.titleSec}>
        <h2 className={styles.title}>
          Expense<span className={styles.menuTitle}>Management</span>
        </h2>
        {subscriptionType && subscriptionType !== SUBSCRIPTION_TYPE.BASIC && (
          <div style={{ justifyContent: 'flex-end' }}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>Export As</label>
            </div>
            <Button
              variant="contained"
              color="primary"
              className={styles.actionBtn}
              style={{ backgroundColor: '#d81b60', marginRight: 5 }}
              onClick={handleExportToExcel}
            >
              <ImportExportIcon className={styles.actionBtnIcon} />
              CSV
            </Button>
          </div>
        )}
      </div>
      {subscriptionType && subscriptionType === SUBSCRIPTION_TYPE.BASIC && (
        <RestrictionInfo
          title={'Feature not available '}
          content={
            'To get customer support options upgrade subscription to premium.'
          }
        />
      )}
      {subscriptionType && subscriptionType !== SUBSCRIPTION_TYPE.BASIC && (
        <div className={styles.changeable}>
          <div className={styles.filterSec}>
            <div className={styles.headTitle}>
              <h2 className={styles.subTitle}>Expense</h2>
            </div>

            <div className={styles.filerInputSec}>
              {userHasWritePermission && (
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ backgroundColor: '#ff851b' }}
                  className={styles.actionBtn}
                  onClick={handleNewExpenseClick}
                >
                  <NoteAddIcon className={styles.actionBtnIcon} />
                  New Expense
                </Button>
              )}
              <div className={styles.searchSec}>
                <input
                  type="text"
                  value={searchText}
                  onChange={(e) => {
                    handleSearch(e.target.value);
                  }}
                  className={styles.searchInput}
                  placeholder="Search items"
                />
                <SearchIcon className={styles.searchIcon} />
              </div>
            </div>
          </div>
          <div className={styles.actionButtons}>
            <div className={styles.filterDiv}>
              <div style={{ paddingBottom: '4px' }}>
                <label className={styles.label}>From</label>
              </div>
              <div>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableFuture
                    variant="outlined"
                    format="dd/MM/yyyy"
                    id="date-picker-from"
                    className={styles.dateBox}
                    value={startDate}
                    onChange={(date) => {
                      handleDateChange(date, endDate);
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'Change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
            <div className={styles.filterDiv}>
              <div style={{ paddingBottom: '4px' }}>
                <label className={styles.label}>To</label>
              </div>
              <div>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableFuture
                    variant="outlined"
                    format="dd/MM/yyyy"
                    id="date-picker-to"
                    className={styles.dateBox}
                    value={endDate}
                    onChange={(date) => {
                      handleDateChange(startDate, date).then();
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'Change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
          </div>
        </div>
      )}
      {errorMsg && (
        <div className={styles.marginTop}>
          <Error title={errorMsg} />
        </div>
      )}
      {successMsg && (
        <div className={styles.marginTop}>
          <Success title={successMsg} />
        </div>
      )}

      {subscriptionType &&
        subscriptionType !== SUBSCRIPTION_TYPE.BASIC &&
        expenses &&
        (expenses.length ? (
          <DataTable
            columns={headerData}
            rows={expenses}
            summary={{ type: 'Total', amount: expenseAmountSum }}
          />
        ) : (
          <Info
            title={'No expenses with the current filter'}
            content={
              'You have no expenses to list with current filter configuration. Please clear the filters or create a expenses'
            }
          />
        ))}
      {showExpenseFormDialog && (
        <ExpenseFormDialog
          expense={selectedExpense}
          setErrorMsg={setErrorMsg}
          setSuccessMsg={setSuccessMsg}
          handleClose={handleFormCancel}
          handleSuccess={handleFormSuccess}
        />
      )}
      {showDeleteConfirm && (
        <ConfirmPopup
          data={selectedExpense}
          handleClose={handleDeleteCancel}
          handleOkay={handleDeleteExpense}
          alertTitle={'Confirm delete'}
          successBtnName={'Delete'}
          alertContent={
            "Deleted expense can't be restored, Are you sure to delete the expense?"
          }
        />
      )}
    </div>
  );
}

export default withConsoleBase(Expenses);
