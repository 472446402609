import { Grid, TextField, Button } from '@material-ui/core';
import React, { useState } from 'react';
import styles from './styles.module.css';
import CloseIcon from '@material-ui/icons/Close';
import CustomerService from '../../services/CustomerService';
import { AlertSetter } from '../utils/Alert/AlertSetter';
import { DialogActions } from '@mui/material';

export default function NewCustomerDialog(props) {
  const { toggleAddTab, setErrorMsg, setSuccessMsg } = props;
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [location, setLocation] = useState('');

  const createNewCustomer = async () => {
    const data = {
      name,
      phone,
      location,
    };

    try {
      await CustomerService.createCustomer(data);
      AlertSetter(setSuccessMsg, 'Customer created successfully');
    } catch (err) {
      AlertSetter(setErrorMsg, err.message);
    }
    toggleAddTab('save');
  };

  return (
    <div className={styles.popUp}>
      <div className={styles.contentWrapper}>
        <div className={styles.headSec}>
          <h2 className={styles.editTitle}>Create New Customer</h2>
          <CloseIcon onClick={toggleAddTab} />
        </div>
        <div className={styles.inputSec}>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid
              item
              xs={4}
              sm={4}
              spacing={2}
              justify="flex-end"
              className={styles.inputLabelContainer}
            >
              <h3 className={styles.inputLabel}> Customer Name</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Customer Name"
                variant="outlined"
                size="small"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid
              item
              xs={4}
              sm={4}
              spacing={2}
              justify="flex-end"
              className={styles.inputLabelContainer}
            >
              <h3 className={styles.inputLabel}>Phone</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Phone"
                variant="outlined"
                size="small"
                type="number"
                className={styles.numberInput}
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid
              item
              xs={4}
              sm={4}
              spacing={2}
              justify="flex-end"
              className={styles.inputLabelContainer}
            >
              <h3 className={styles.inputLabel}>Place</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Place"
                variant="outlined"
                size="small"
                name="location"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={toggleAddTab}
              className={styles.closeBtn}
            >
              close
            </Button>
            <Button
              variant="contained"
              size="small"
              color="primary"
              style={{ backgroundColor: '#00a65a' }}
              disabled={name === '' || phone === '' || location === ''}
              onClick={createNewCustomer}
            >
              Create Customer
            </Button>
          </DialogActions>
        </div>
      </div>
    </div>
  );
}
