import { Grid, TextField, Button } from '@material-ui/core';
import React, { useState, useContext } from 'react';
import styles from './styles.module.css';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { USER_PERMISSIONS } from '../../const';
import { UserContext } from '../../Context/UserContext';
import { DialogActions } from '@mui/material';

export default function AssignKeyDialog(props) {
  const { closeTab, keyData, items, selectedItem, keyUpadateHandler } = props;
  const { user } = useContext(UserContext);
  const [key, setKey] = useState(keyData);
  const [item, setItem] = useState();
  const [itemPlu, setItemPlu] = useState();

  const userHasShopWritePermission =
    user?.shop_permissions?.shop_permission === USER_PERMISSIONS.WRITE;

  const handleKeyChange = (event) => setKey(event.target.value);
  const updateKeyCombination = () => {
    keyUpadateHandler(key, item.id);
    closeTab();
  };
  return (
    <div className={styles.popUp}>
      <div className={styles.contentWrapper}>
        <div className={styles.headSec}>
          <h2 className={styles.editTitle}>Change key combination</h2>
          <CloseIcon onClick={closeTab} />
        </div>
        <div className={styles.inputSec}>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid
              item
              xs={4}
              sm={4}
              spacing={2}
              justify="flex-end"
              className={styles.inputLabelContainer}
            >
              <h3 className={styles.inputLabel}> Key</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Device Key"
                variant="outlined"
                size="small"
                name="name"
                type="number"
                value={key}
                disabled
                className={styles.numberInput}
                onChange={handleKeyChange}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid
              item
              xs={4}
              sm={4}
              spacing={2}
              justify="flex-end"
              className={styles.inputLabelContainer}
            >
              <h3 className={styles.inputLabel}>Item PLU</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <Autocomplete
                value={item?.plu}
                onChange={(e, value) => {
                  setItem(value);
                  setItemPlu(value.plu);
                }}
                disableClearable
                defaultValue={selectedItem ? selectedItem : null}
                inputValue={itemPlu}
                onInputChange={(e, value) => {
                  setItemPlu(value);
                }}
                getOptionLabel={(option) => `${option.plu} - ${option.name}`}
                options={items}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="item PLU"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={closeTab}
              className={styles.closeBtn}
            >
              close
            </Button>
            {userHasShopWritePermission && (
              <Button
                variant="contained"
                size="small"
                color="primary"
                style={{ backgroundColor: '#00a65a' }}
                disabled={!key || !item}
                onClick={updateKeyCombination}
              >
                save
              </Button>
            )}
          </DialogActions>
        </div>
      </div>
    </div>
  );
}
