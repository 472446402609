import React, { useState, useEffect, useContext } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { TextField, MenuItem, Button } from '@material-ui/core';
import styles from './styles.module.css';
import DataTable from '../../utils/DataTable';
import BillSummaryReportService from '../../../services/BillSummaryReportService';
import DateTimeHelpers from '../../../helpers/DateTimeHelpers';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { saveAs } from 'file-saver';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import DeviceService from '../../../services/DeviceService';
import Info from '../../utils/Alert/Info';
import BillDetailsDialog from '../../popups/BillDetailsDialog';
import CustomerService from '../../../services/CustomerService';
import Loader from '../../utils/Loading';
import setDelay from '../../../helpers/LoadingDelay';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import GetApp from '@material-ui/icons/GetApp';
import { STORAGE_KEYS, SUBSCRIPTION_TYPE } from '../../../const';
import { AlertSetter } from '../../utils/Alert/AlertSetter';
import Success from '../../utils/Alert/Success';
import Error from '../../utils/Alert/Error';
import { ShopContext } from '../../../Context/ShopContext';

function BillSummaryReport() {
  const { shop } = useContext(ShopContext);

  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [filterFromDate, setFilterFromDate] = useState(new Date());
  const [filterToDate, setFileterToDate] = useState(new Date());
  const [sorting, setSorting] = useState('desc');
  const [currency, setCurrency] = useState('');
  const [machineName, setMachineName] = useState('all');
  const [machineNames, setMachineNames] = useState('');
  const [items, setItems] = useState('');
  const [searchText, setSearchText] = useState('');
  const [filteredItems, setFilteredItems] = useState('');
  const [viewData, setViewData] = useState('');
  const [billDetails, setBillDetails] = useState('');
  const [customers, setCustomers] = useState('');
  const [errorMsg, setErrorMsg] = useState();
  const [successMsg, setSuccessMsg] = useState();
  const [totalRow, setTotalRow] = useState({
    bill_no: '',
    date: '',
    machine_no: '',
    time: '',
    items: '',
    vat_amount: 'Total',
    total: '',
  });

  useEffect(() => {
    getMachineName();
    loadBillSummaryReports(filterFromDate, filterToDate);
    getCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getCustomers = async () => {
    setLoadingIndicator(true);
    const res = await CustomerService.getCustomers();
    setCustomers(res);
    setDelay(setLoadingIndicator);
  };
  const getMachineName = async () => {
    const res = await DeviceService.getDevices();
    setMachineNames(res);
  };
  const toExcelBtnPressed = async (e) => {
    try {
      const data = {
        date: DateTimeHelpers.convertDateToDMY(filterFromDate),
        to_date: DateTimeHelpers.convertDateToDMY(filterToDate),
        target: 'excel',
      };
      const res = await BillSummaryReportService.getBillSummaryExcel(data);
      const blob = new Blob([res.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, `bill-summary-${data.date}-${data.to_date}.xlsx`);
      AlertSetter(setSuccessMsg, 'Exported to excel successfully');
    } catch (err) {
      AlertSetter(setErrorMsg, err.message);
    }
  };

  const toCsvBtnPressed = async (e) => {
    try {
      const data = {
        date: DateTimeHelpers.convertDateToDMY(filterFromDate),
        to_date: DateTimeHelpers.convertDateToDMY(filterToDate),
        target: 'csv',
      };
      const res = await BillSummaryReportService.getBillSummaryCsv(data);
      const blob = new Blob([res.data], {
        type: 'text/csv',
      });
      saveAs(blob, `bill-summary-${data.date}-${data.to_date}.csv`);
      AlertSetter(setSuccessMsg, 'Exported to CSV successfully');
    } catch (err) {
      AlertSetter(setErrorMsg, err.message);
    }
  };
  const toggleViewItems = async (bill) => {
    if (bill === '') return setViewData(false);
    const data = {
      bill_no: bill['bill_no'],
      machine_no: bill['machine_no'],
    };
    setLoadingIndicator(true);
    setBillDetails({
      bill_no: bill['bill_no'],
      machine_no: bill['machine_no'],
    });
    const res = await BillSummaryReportService.getBillDetails(data);
    setViewData({
      customer: res.customer,
      items: [
        ...res.items,
        {
          item: {
            name: '',
            qty: '',
            price: 'Total',
          },
          total: bill.total,
        },
      ],
    });
    setDelay(setLoadingIndicator);
  };
  const loadBillSummaryReports = async (
    from,
    to,
    sort = sorting,
    mac = machineName
  ) => {
    setLoadingIndicator(true);
    try {
      const data = {
        date: DateTimeHelpers.convertDateToDMY(from),
        to_date: DateTimeHelpers.convertDateToDMY(to),
        sort_bill_num: sort,
        category_id: '0',
        filter_mac: mac === 'all' ? '' : [mac],
      };
      const resp = await BillSummaryReportService.getBillSummaryReport(data);
      let itemData = resp.reports;
      itemData.push();
      setTotalRow({
        ...totalRow,
        total: Number(resp.sales_total),
      });
      setItems(resp.reports);
      setFilteredItems(resp.reports);
      setCurrency(resp.currency);
    } catch (err) {
      AlertSetter(setErrorMsg, err.message);
    }

    setDelay(setLoadingIndicator);
  };
  const handleSearch = (value) => {
    setSearchText(value);
    setFilteredItems(
      items.filter(
        (item) =>
          item.bill_no.toString().includes(value.toLowerCase()) ||
          item.date.includes(value.toLowerCase())
      )
    );
  };
  const billToPdf = async (bill) => {
    const authToken = localStorage.getItem(STORAGE_KEYS.TOKEN);
    const shopId = localStorage.getItem(STORAGE_KEYS.SHOP_ID);
    window.open(
      `https://yscloud.in/backend/api/reports/bills/details/pdf/?token=${authToken}&shop_id=${shopId}&machine_no=${bill.machine_no}&bill_no=${bill.bill_no}`
    );
  };

  const subscriptionType = shop?.subscription?.type || '';
  const headerData = [
    {
      label: 'Bill No',
      id: 'bill_no',
      type: 'text',
    },
    {
      label: 'Machine',
      id: 'machine_no',
      type: 'text',
    },
    {
      label: 'Date',
      id: 'date',
      type: 'text',
    },
    {
      label: 'Time',
      id: 'time',
      type: 'text',
    },
    {
      label: 'No Of Products',
      id: 'items',
      type: 'text',
    },
    {
      label: 'GST Amount',
      id: 'vat_amount',
      type: 'floatAmount',
    },
  ];
  if (subscriptionType === SUBSCRIPTION_TYPE.PREMIUM) {
    headerData.push({
      label: 'Purchase Cost',
      id: 'purchase_cost',
      type: 'callback',
      viewRender: (item) => {
        if (item.purchase_cost === '' || isNaN(item.purchase_cost)) {
          return item.purchase_cost;
        }
        return `${currency || ''} ${item.purchase_cost.toFixed(2)}`;
      },
    });
    headerData.push({
      label: 'Purchase Tax',
      id: 'purchase_tax',
      type: 'callback',
      viewRender: (item) => {
        if (item.purchase_tax === '' || isNaN(item.purchase_tax)) {
          return item.purchase_tax;
        }
        return `${currency || ''} ${item.purchase_tax.toFixed(2)}`;
      },
    });
    headerData.push({
      label: 'Expense',
      id: 'purchase_expense',
      type: 'callback',
      viewRender: (item) => {
        if (item.purchase_expense === '' || isNaN(item.purchase_expense)) {
          return item.purchase_expense;
        }
        return `${currency || ''} ${item.purchase_expense.toFixed(2)}`;
      },
    });
    headerData.push({
      label: 'Profit*',
      id: 'profit',
      type: 'callback',
      viewRender: (item) => {
        if (item.profit === '' || isNaN(item.profit)) {
          return item.profit;
        }
        return `${currency || ''} ${item.profit.toFixed(2)}`;
      },
    });
  }
  headerData.push({
    label: 'Total Price',
    id: 'total',
    type: 'callback',
    viewRender: (item) => {
      return `${currency || ''} ${item.total.toFixed(1)}`;
    },
  });
  headerData.push({
    label: 'View Products',
    id: 'view_Items',
    type: 'button',
    title: 'View items',
    clickHandler: toggleViewItems,
  });
  headerData.push({
    label: 'Download Bill',
    id: 'download',
    type: 'callback',
    viewRender: (bill) => (
      <div style={{ textAlign: 'center', cursor: 'pointer' }}>
        <GetApp onClick={() => billToPdf(bill)} />
      </div>
    ),
  });

  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loadingIndicator} />

      <div className={styles.titleSec}>
        <span className={styles.title}>
          Reports<span className={styles.menuTitle}>Generation</span>
        </span>
        <div style={{ justifyContent: 'flex-end' }}>
          <div style={{ paddingBottom: '4px' }}>
            <label className={styles.label}>Export As</label>
          </div>
          <Button
            variant="contained"
            color="primary"
            className={styles.actionBtn}
            style={{ backgroundColor: '#d81b60', marginRight: 5 }}
            onClick={toExcelBtnPressed}
          >
            <ImportExportIcon className={styles.actionBtnIcon} />
            Excel
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={styles.actionBtn}
            style={{ backgroundColor: '#00a65a' }}
            onClick={toCsvBtnPressed}
          >
            <ImportExportIcon className={styles.actionBtnIcon} />
            CSV
          </Button>
        </div>
      </div>
      <div className={styles.changeable}>
        <div className={styles.filterSec}>
          <div className={styles.headTitle}>
            <h2 className={styles.subTitle}>
              Bill Summary Report for{'  '}
              {DateTimeHelpers.convertDateToDMY(filterFromDate)}
              to
              {DateTimeHelpers.convertDateToDMY(filterToDate)}
            </h2>
          </div>
          <div className={styles.filerInputSec}>
            <div className={styles.searchSec}>
              <input
                type="text"
                value={searchText}
                onChange={(e) => {
                  handleSearch(e.target.value);
                }}
                className={styles.searchInput}
                placeholder="search items"
              />
              <SearchIcon className={styles.searchIcon} />
            </div>
          </div>
        </div>
        <div className={styles.actionButtons}>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>From</label>
            </div>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableFuture
                  variant="outlined"
                  format="dd/MM/yyyy"
                  id="date-picker-from"
                  className={styles.dateBox}
                  value={filterFromDate}
                  onChange={(date) => {
                    setFilterFromDate(date);
                    loadBillSummaryReports(date, filterToDate);
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'Change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>To</label>
            </div>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableFuture
                  variant="outlined"
                  format="dd/MM/yyyy"
                  id="date-picker-to"
                  className={styles.dateBox}
                  value={filterToDate}
                  onChange={(date) => {
                    setFileterToDate(date);
                    loadBillSummaryReports(filterFromDate, date);
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'Change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>Machine No/Name</label>
            </div>
            <div>
              <TextField
                select
                name="machineName"
                size="small"
                variant="outlined"
                defaultValue={machineName}
                color="primary"
                className={styles.dateBox}
                onChange={(event) => {
                  setMachineName(event.target.value);
                  loadBillSummaryReports(
                    filterFromDate,
                    filterToDate,
                    sorting,
                    event.target.value
                  );
                }}
              >
                {machineNames &&
                  machineNames.map((machine) => (
                    <MenuItem value={machine.machine_number}>
                      {`${machine.machine_number} - ${machine.machine_name}`}
                    </MenuItem>
                  ))}
                <MenuItem value="all">All</MenuItem>
              </TextField>
            </div>
          </div>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>Bill No</label>
            </div>
            <div>
              <TextField
                select
                name="billNo"
                size="small"
                variant="outlined"
                color="primary"
                style={{ width: 135 }}
                defaultValue={sorting}
                onChange={(event) => {
                  setSorting(event.target.value);
                  loadBillSummaryReports(
                    filterFromDate,
                    filterToDate,
                    event.target.value
                  );
                }}
              >
                <MenuItem value={'desc'}>Descending</MenuItem>
                <MenuItem value={'asc'}>Ascending</MenuItem>
              </TextField>
            </div>
          </div>
        </div>
      </div>
      {errorMsg && (
        <div className={styles.marginTop}>
          <Error title={errorMsg} />
        </div>
      )}
      {successMsg && (
        <div className={styles.marginTop}>
          <Success title={successMsg} />
        </div>
      )}

      {items &&
        (filteredItems && filteredItems.length ? (
          <DataTable
            columns={headerData}
            rows={filteredItems ? filteredItems : items}
            rowKey="id"
          />
        ) : (
          <Info
            title={'You have no report to show for this date'}
            content={
              'Reports are generated based on the sales. Please verify your inputs to the generate report form.'
            }
          />
        ))}
      {viewData && (
        <BillDetailsDialog
          toggleItemsTab={toggleViewItems}
          rowData={viewData}
          details={billDetails}
          setSuccessMsg={setSuccessMsg}
          setErrorMsg={setErrorMsg}
          customers={customers}
        />
      )}
    </div>
  );
}

export default withConsoleBase(BillSummaryReport);
