import { Grid, TextField, Button, CardMedia } from '@material-ui/core';
import React from 'react';
import styles from './styles.module.css';
import CloseIcon from '@material-ui/icons/Close';
import { useState } from 'react';
import ItemService from '../../services/ItemService';
import { AlertSetter } from '../utils/Alert/AlertSetter';
import { DialogActions } from '@mui/material';

export default function AddCategoryDialog(props) {
  const { toggleAddTab, setErr, setSuccessMsg } = props;
  const [categoryName, setCategoryName] = useState('');
  const [categoryDesc, setCategoryDesc] = useState('');
  const [imageData, setImageData] = useState('');
  const uploadImage = (image, categId) => {
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onloadend = async () => {
      const data = {
        image: reader.result,
      };
      await ItemService.uploadCategoryImage(data, categId);
    };
  };
  const handleAddCategory = async () => {
    try {
      const data = {
        name: categoryName,
        description: categoryDesc,
      };
      const res = await ItemService.addItemCategory(data);
      uploadImage(imageData, res.id);
      AlertSetter(setSuccessMsg, 'Category created successfully');
      toggleAddTab('');
    } catch (err) {
      AlertSetter(setErr, err.message);
    }
  };
  return (
    <div className={styles.popUp}>
      <div className={styles.contentWrapper}>
        <div className={styles.headSec}>
          <h2 className={styles.editTitle}>Create New Category</h2>
          <CloseIcon onClick={toggleAddTab} />
        </div>
        <div className={styles.inputSec}>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid
              item
              xs={4}
              sm={4}
              spacing={2}
              justify="flex-end"
              className={styles.inputLabelContainer}
            >
              <h3 className={styles.inputLabel}>Category Name</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Category Name"
                variant="outlined"
                size="small"
                fullWidth
                value={categoryName}
                onChange={(e) => {
                  setCategoryName(e.target.value);
                }}
              />
            </Grid>
          </Grid>

          <Grid container className={styles.inputRow} alignItems="center">
            <Grid
              item
              xs={4}
              sm={4}
              spacing={2}
              justify="flex-end"
              className={styles.inputLabelContainer}
            >
              <h3 className={styles.inputLabel}>Category Description</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Category Description"
                variant="outlined"
                size="small"
                fullWidth
                value={categoryDesc}
                onChange={(e) => {
                  setCategoryDesc(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid
              item
              xs={4}
              sm={4}
              spacing={2}
              justify="flex-end"
              className={styles.inputLabelContainer}
            >
              <h3 className={styles.inputLabel}>Category Image</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <div style={{ fontSize: 10, paddingBottom: 5 }}>
                150px * 150px size recommended{' '}
              </div>
              {imageData && (
                <CardMedia image={URL.createObjectURL(imageData)} />
              )}

              <input
                accept="image/*"
                type="file"
                hidden
                id="upload"
                onChange={(e) => {
                  setImageData(e.target.files[0]);
                }}
              />
              <Button
                variant="contained"
                size="small"
                className={styles.uploadBtn}
              >
                <label for="upload">{imageData ? 'change' : 'select'}</label>
              </Button>
            </Grid>
          </Grid>

          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={toggleAddTab}
              className={styles.closeBtn}
            >
              close
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ backgroundColor: '#00a65a' }}
              disabled={categoryName === ''}
              onClick={handleAddCategory}
            >
              Create Category
            </Button>
          </DialogActions>
        </div>
      </div>
    </div>
  );
}
