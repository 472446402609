import React, { useState, useEffect, useContext } from 'react';
import { Grid, Button, MenuItem, TextField, Paper } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import styles from './styles.module.css';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import { ShopContext } from '../../../Context/ShopContext';
import RestrictionInfo from '../../utils/Alert/RestrictionInfo';
import { AlertSetter } from '../../utils/Alert/AlertSetter';
import UserService from '../../../services/UserService';
import Loader from '../../utils/Loading';
import Error from '../../utils/Alert/Error';
import Success from '../../utils/Alert/Success';
import { SUBSCRIPTION_TYPE } from '../../../const';

function UserPermissionDetail() {
  const { id } = useParams();
  const { shop } = useContext(ShopContext);
  const subscriptionType = shop?.subscription?.type;
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const [successMsg, setSuccessMsg] = useState();
  const [userDetail, setUserDetail] = useState('');
  const [permissions, setPermissions] = useState({
    id: null,
    shop: null,
    user: null,
    username: '',
    customers_permission: '',
    damage_permission: '',
    devices_permission: '',
    products_permission: '',
    purchase_permission: '',
    reports_permission: '',
    shop_permission: '',
    stock_permission: '',
    users_permission: '',
    expense_permission: '',
  });
  const handlePermissionChange = (e) =>
    setPermissions({
      ...permissions,
      [e.target.name]: e.target.value,
    });
  useEffect(() => {
    if (subscriptionType && subscriptionType !== SUBSCRIPTION_TYPE.BASIC) {
      loadUserDetail();
    }
    // eslint-disable-next-line
  }, [subscriptionType]);

  const loadUserDetail = async () => {
    setLoadingIndicator(true);
    try {
      const userDetail = await UserService.getShopUserDetailsAndPermissions(id);
      setUserDetail(userDetail);
      setPermissions(userDetail.shop_permissions);
    } catch (err) {
      AlertSetter(setErrorMsg, err.message);
    }
    setLoadingIndicator(false);
  };
  const updateBtnPressed = async () => {
    try {
      await UserService.UpdateUserPermissions(permissions, id);
      AlertSetter(setSuccessMsg, 'Updated user permission successfully');
    } catch (error) {
      AlertSetter(setErrorMsg, error.message);
    }
  };
  return (
    <>
      <div className={styles.contentLayoutColumn}>
        <div className={styles.headingDiv}>
          <span className={styles.pageHead}>Detailed User Permissions</span>
        </div>
        {subscriptionType && subscriptionType === SUBSCRIPTION_TYPE.BASIC && (
          <RestrictionInfo
            title={'Feature not available '}
            content={
              'To get bill format options upgrade subscription to premium'
            }
          />
        )}
        {subscriptionType && subscriptionType !== SUBSCRIPTION_TYPE.BASIC && (
          <>
            <Loader isOpen={loadingIndicator} />
            <div className={styles.changeable}>
              <div style={{ padding: 15 }}>
                <span className={styles.pageHead}>User Details</span>
              </div>
              <Grid container spacing={1} className={styles.userDetailDiv}>
                <Paper className={styles.userBox}>
                  <div className={styles.userCard}>
                    <span className={styles.fieldTitleSmall}>First Name :</span>
                    <span className={styles.fieldTitle}>
                      {userDetail.first_name}
                    </span>
                  </div>
                </Paper>
                <Paper className={styles.userBox}>
                  <div className={styles.userCard}>
                    <span className={styles.fieldTitleSmall}>Last Name :</span>
                    <span className={styles.fieldTitle}>
                      {userDetail.last_name}
                    </span>
                  </div>
                </Paper>
                <Paper className={styles.userBox}>
                  <div className={styles.userCard}>
                    <span className={styles.fieldTitleSmall}>Username :</span>
                    <span className={styles.fieldTitle}>
                      {userDetail.username}
                    </span>
                  </div>
                </Paper>
              </Grid>
              {errorMsg && (
                <div className={styles.padding15}>
                  <Error title={errorMsg} />
                </div>
              )}
              {successMsg && (
                <div className={styles.padding15}>
                  <Success title={successMsg} />
                </div>
              )}
              <Grid
                container
                spacing={1}
                style={{ padding: 15, paddingTop: 30 }}
              >
                <Grid item xs={6} sm={12} className={styles.BillHeader}>
                  <div
                    className={styles.fieldHead}
                    style={{ marginBottom: 15 }}
                  >
                    <span className={styles.pageHead}>Permissions</span>
                  </div>
                </Grid>
                <Grid item xs={6} sm={12} className={styles.BillHeader}>
                  <div className={styles.fieldHead}>
                    <span className={styles.fieldTitleSmall}>
                      Products Permission
                    </span>
                  </div>
                  <div className={styles.fieldInput}>
                    <TextField
                      select
                      style={{ minWidth: 200 }}
                      size="small"
                      name="products_permission"
                      value={permissions.products_permission}
                      variant="outlined"
                      color="primary"
                      onChange={handlePermissionChange}
                    >
                      <MenuItem value={'deny'}>No Permission</MenuItem>
                      <MenuItem value={'read'}>Read Permission</MenuItem>
                      <MenuItem value={'write'}>Write Permission</MenuItem>
                    </TextField>
                  </div>
                </Grid>
                <Grid item xs={6} sm={12} className={styles.BillHeader}>
                  <div className={styles.fieldHead}>
                    <span className={styles.fieldTitleSmall}>
                      Devices Permission
                    </span>
                  </div>
                  <div className={styles.fieldInput}>
                    <TextField
                      select
                      style={{ minWidth: 200 }}
                      size="small"
                      name="devices_permission"
                      value={permissions.devices_permission}
                      variant="outlined"
                      color="primary"
                      onChange={handlePermissionChange}
                    >
                      <MenuItem value={'deny'}>No Permission</MenuItem>
                      <MenuItem value={'read'}>Read Permission</MenuItem>
                      <MenuItem value={'write'}>Write Permission</MenuItem>
                    </TextField>
                  </div>
                </Grid>
                <Grid item xs={6} sm={12} className={styles.BillHeader}>
                  <div className={styles.fieldHead}>
                    <span className={styles.fieldTitleSmall}>
                      Customers Permission
                    </span>
                  </div>
                  <div className={styles.fieldInput}>
                    <TextField
                      select
                      style={{ minWidth: 200 }}
                      size="small"
                      name="customers_permission"
                      value={permissions.customers_permission}
                      variant="outlined"
                      color="primary"
                      onChange={handlePermissionChange}
                    >
                      <MenuItem value={'deny'}>No Permission</MenuItem>
                      <MenuItem value={'read'}>Read Permission</MenuItem>
                      <MenuItem value={'write'}>Write Permission</MenuItem>
                    </TextField>
                  </div>
                </Grid>
                <Grid item xs={6} sm={12} className={styles.BillHeader}>
                  <div className={styles.fieldHead}>
                    <span className={styles.fieldTitleSmall}>
                      Purchase Permission
                    </span>
                  </div>
                  <div className={styles.fieldInput}>
                    <TextField
                      select
                      style={{ minWidth: 200 }}
                      size="small"
                      name="purchase_permission"
                      value={permissions.purchase_permission}
                      variant="outlined"
                      color="primary"
                      onChange={handlePermissionChange}
                    >
                      <MenuItem value={'deny'}>No Permission</MenuItem>
                      <MenuItem value={'read'}>Read Permission</MenuItem>
                      <MenuItem value={'write'}>Write Permission</MenuItem>
                    </TextField>
                  </div>
                </Grid>
                <Grid item xs={6} sm={12} className={styles.BillHeader}>
                  <div className={styles.fieldHead}>
                    <span className={styles.fieldTitleSmall}>
                      Damage Permission
                    </span>
                  </div>
                  <div className={styles.fieldInput}>
                    <TextField
                      select
                      style={{ minWidth: 200 }}
                      size="small"
                      name="damage_permission"
                      value={permissions.damage_permission}
                      variant="outlined"
                      color="primary"
                      onChange={handlePermissionChange}
                    >
                      <MenuItem value={'deny'}>No Permission</MenuItem>
                      <MenuItem value={'read'}>Read Permission</MenuItem>
                      <MenuItem value={'write'}>Write Permission</MenuItem>
                    </TextField>
                  </div>
                </Grid>
                <Grid item xs={6} sm={12} className={styles.BillHeader}>
                  <div className={styles.fieldHead}>
                    <span className={styles.fieldTitleSmall}>
                      Stock Permission
                    </span>
                  </div>
                  <div className={styles.fieldInput}>
                    <TextField
                      select
                      style={{ minWidth: 200 }}
                      size="small"
                      name="stock_permission"
                      value={permissions.stock_permission}
                      variant="outlined"
                      color="primary"
                      onChange={handlePermissionChange}
                    >
                      <MenuItem value={'deny'}>No Permission</MenuItem>
                      <MenuItem value={'read'}>Read Permission</MenuItem>
                      <MenuItem value={'write'}>Write Permission</MenuItem>
                    </TextField>
                  </div>
                </Grid>
                <Grid item xs={6} sm={12} className={styles.BillHeader}>
                  <div className={styles.fieldHead}>
                    <span className={styles.fieldTitleSmall}>
                      Reports Permission
                    </span>
                  </div>
                  <div className={styles.fieldInput}>
                    <TextField
                      select
                      style={{ minWidth: 200 }}
                      size="small"
                      name="reports_permission"
                      value={permissions.reports_permission}
                      variant="outlined"
                      color="primary"
                      onChange={handlePermissionChange}
                    >
                      <MenuItem value={'deny'}>No Permission</MenuItem>
                      <MenuItem value={'write'}>Generate Reports</MenuItem>
                    </TextField>
                  </div>
                </Grid>
                <Grid item xs={6} sm={12} className={styles.BillHeader}>
                  <div className={styles.fieldHead}>
                    <span className={styles.fieldTitleSmall}>
                      Shop Permission
                    </span>
                  </div>
                  <div className={styles.fieldInput}>
                    <TextField
                      select
                      style={{ minWidth: 200 }}
                      size="small"
                      name="shop_permission"
                      value={permissions.shop_permission}
                      variant="outlined"
                      color="primary"
                      onChange={handlePermissionChange}
                    >
                      <MenuItem value={'deny'}>No Permission</MenuItem>
                      <MenuItem value={'read'}>Read Permission</MenuItem>
                      <MenuItem value={'write'}>Write Permission</MenuItem>
                    </TextField>
                  </div>
                </Grid>
                <Grid item xs={6} sm={12} className={styles.BillHeader}>
                  <div className={styles.fieldHead}>
                    <span className={styles.fieldTitleSmall}>
                      Users Permission
                    </span>
                  </div>
                  <div className={styles.fieldInput}>
                    <TextField
                      select
                      style={{ minWidth: 200 }}
                      size="small"
                      name="users_permission"
                      value={permissions.users_permission}
                      variant="outlined"
                      color="primary"
                      onChange={handlePermissionChange}
                    >
                      <MenuItem value={'deny'}>No Permission</MenuItem>
                      <MenuItem value={'read'}>Read Permission</MenuItem>
                      <MenuItem value={'write'}>Write Permission</MenuItem>
                    </TextField>
                  </div>
                </Grid>
                <Grid item xs={6} sm={12} className={styles.BillHeader}>
                  <div className={styles.fieldHead}>
                    <span className={styles.fieldTitleSmall}>
                      Expense Permission
                    </span>
                  </div>
                  <div className={styles.fieldInput}>
                    <TextField
                      select
                      style={{ minWidth: 200 }}
                      size="small"
                      name="expense_permission"
                      value={permissions.expense_permission}
                      variant="outlined"
                      color="primary"
                      onChange={handlePermissionChange}
                    >
                      <MenuItem value={'deny'}>No Permission</MenuItem>
                      <MenuItem value={'read'}>Read Permission</MenuItem>
                      <MenuItem value={'write'}>Write Permission</MenuItem>
                    </TextField>
                  </div>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={1}
                style={{ marginTop: 4, marginBottom: 4, padding: 15 }}
              >
                <Grid item xs={6} sm={12}>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={updateBtnPressed}
                    color="secondary"
                    style={{ backgroundColor: '#00a65a' }}
                  >
                    Update Permissions
                  </Button>
                </Grid>
              </Grid>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default withConsoleBase(UserPermissionDetail);
