import BackendService from './BackendService';

async function getStockReport(data = {}) {
  return BackendService.postData('/stock_reports/', data);
}

const StockReportService = {
  getStockReport,
};

export default StockReportService;
