import NavBar from '../NavBar';
import TitleBar from '../TitleBar';
import styles from './styles.module.css';
import Loader from '../Loading';
import { STORAGE_KEYS } from '../../../const';

function withConsoleBase(ConsoleComponent) {
  const shopId = localStorage.getItem(STORAGE_KEYS.SHOP_ID);
  return () => {
    return (
      <>
        {shopId !== '-1' ? (
          <>
            <TitleBar />
            <div className={styles.ContentsWrapper}>
              <div className={styles.NavbarWrapper}>
                <NavBar />
              </div>
              <div className={styles.ContentsArea}>
                <ConsoleComponent />
              </div>
            </div>
          </>
        ) : (
          <Loader isOpen={true} />
        )}
      </>
    );
  };
}

export default withConsoleBase;
