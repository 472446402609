import { createContext, useState } from 'react';
export const ShopContext = createContext();

export default function ShopProvider(props) {
  const [shop, setShop] = useState();
  return (
    <ShopContext.Provider value={{ shop, setShop }}>
      {props.children}
    </ShopContext.Provider>
  );
}
