import { Grid } from '@material-ui/core';
import styles from './styles.module.css';
import BillFormat from './BillFormat';
import ShopSettings from './ShopSettings';
import ShopUsersList from './ShopUsersList';
import ShopProfile from './ShopProfile';
import { useState } from 'react';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import ShortKey from './ShortKey';

const tabStyleActive = {
  backgroundColor: '#ff7f00',
  color: '#fff',
};

const tabStyle = {
  color: '#000',
};

const NAVIGATION_TAB = {
  PROFILE: 5,
  BILL_FORMAT: 1,
  SHORT_KEY: 2,
  SETTINGS: 3,
  USERS: 4,
};

function SettingsPage(props) {
  const [selectedTab, setSelectedTab] = useState(5);
  return (
    <div className={styles.contentsArea}>
      <div className={styles.headingDiv}>
        <span className={styles.pageHead}>Settings</span>
      </div>
      <div className={styles.changeable}>
        <Grid className={styles.settingsTab} container>
          <div
            className={styles.navItem}
            style={
              selectedTab === NAVIGATION_TAB.PROFILE ? tabStyleActive : tabStyle
            }
            onClick={() => {
              setSelectedTab(NAVIGATION_TAB.PROFILE);
            }}
          >
            <span className={styles.title}>Profile</span>
          </div>
          <div
            className={styles.navItem}
            style={
              selectedTab === NAVIGATION_TAB.BILL_FORMAT
                ? tabStyleActive
                : tabStyle
            }
            onClick={() => {
              setSelectedTab(NAVIGATION_TAB.BILL_FORMAT);
            }}
          >
            <span className={styles.title}>Bill format</span>
          </div>
          <div
            className={styles.navItem}
            style={
              selectedTab === NAVIGATION_TAB.SHORT_KEY
                ? tabStyleActive
                : tabStyle
            }
            onClick={() => {
              setSelectedTab(NAVIGATION_TAB.SHORT_KEY);
            }}
          >
            <span className={styles.title}>Short Key</span>
          </div>
          <div
            className={styles.navItem}
            style={
              selectedTab === NAVIGATION_TAB.SETTINGS
                ? tabStyleActive
                : tabStyle
            }
            onClick={() => {
              setSelectedTab(NAVIGATION_TAB.SETTINGS);
            }}
          >
            <span className={styles.title}>Settings</span>
          </div>
          <div
            className={styles.navItem}
            style={
              selectedTab === NAVIGATION_TAB.USERS ? tabStyleActive : tabStyle
            }
            onClick={() => {
              setSelectedTab(NAVIGATION_TAB.USERS);
            }}
          >
            <span className={styles.title}>Users</span>
          </div>
        </Grid>

        {selectedTab === NAVIGATION_TAB.BILL_FORMAT && <BillFormat />}
        {selectedTab === NAVIGATION_TAB.SHORT_KEY && <ShortKey />}
        {selectedTab === NAVIGATION_TAB.SETTINGS && <ShopSettings />}
        {selectedTab === NAVIGATION_TAB.PROFILE && <ShopProfile />}
        {selectedTab === NAVIGATION_TAB.USERS && <ShopUsersList />}
      </div>
    </div>
  );
}

export default withConsoleBase(SettingsPage);
