import ysweigh from './ysweigh.png';
import styles from './styles.module.css';
import React from 'react';
import { Grid, Hidden } from '@material-ui/core';
export default function Footer() {
  return (
    <Grid className={styles.footerMainDiv}>
      <Grid container className={styles.upperDiv}>
        <Grid item className={styles.companyDetailDiv}>
          <Grid>
            <img alt="" src={ysweigh} width="95" height="95" />
          </Grid>

          <Hidden xsDown>
            <Grid style={{ marginLeft: 20 }}>
              <div className={styles.footerItem}>
                <h5>Interweighing Pvt Ltd , 49/468-A,</h5>
                <h5>2nd Floor, City Center,</h5>
                <h5>Vyttila, Ernakulam,</h5>
                <h5>Kerala, India - 682019.</h5>
                <h5>Phone : +91 8803333444</h5>
              </div>
            </Grid>
          </Hidden>
        </Grid>
        <Grid item className={styles.companyDetailDiv}>
          <Grid>
            <div className={styles.footerItem}>
              <h5
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  window.open('http://yesweigh.in/about-us.php', '_blank')
                }
              >
                About us
              </h5>
            </div>
            <div className={styles.footerItem}>
              <h5
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  window.open('http://yesweigh.in/contact-us.php', '_blank')
                }
              >
                Contact us
              </h5>
            </div>
            <div className={styles.footerItem}>
              <h5
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  window.open(
                    'https://drive.google.com/file/d/1ZCa7_afvQS6iFFDTyHIvB4h8Gqq9AzBN/view',
                    '_blank'
                  )
                }
              >
                Privacy policy
              </h5>
            </div>
            <div className={styles.footerItem}>
              <h5
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  window.open(
                    'https://drive.google.com/file/d/1ZCa7_afvQS6iFFDTyHIvB4h8Gqq9AzBN/view',
                    '_blank'
                  )
                }
              >
                Return policy
              </h5>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={styles.lowerDiv}>
        <h6
          className={styles.copyRight}
          style={{ color: '#FFF', textAlign: 'center' }}
        >
          Copyright © 2021{' '}
          <span
            style={{ color: '#FF7F00', cursor: 'pointer' }}
            onClick={() => window.open('http://yesweigh.in/', '_blank')}
          >
            Yes Weigh{' '}
          </span>
          | All Rights Reserved. | Powered By{' '}
          <span
            style={{ color: '#FF7F00', cursor: 'pointer' }}
            onClick={() => window.open('https://www.sanoft.com/', '_blank')}
          >
            Sanoft Technologies Pvt Ltd.
          </span>
        </h6>
      </Grid>
    </Grid>
  );
}
