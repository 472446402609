function getMachineString(machine) {
  return machine.machine_name
    ? `${machine.machine_number} - ${machine.machine_name}`
    : machine.machine_number;
}

const MachineHelper = {
  getMachineString,
};

export default MachineHelper;
