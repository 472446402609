import BackendService from './BackendService';

async function getItems(data = {}) {
  return BackendService.getData('/items/', data);
}
async function getItemNames(data = {}) {
  return BackendService.getData('/items/name_list/', data);
}
async function updateItem(itemId, data = {}) {
  return BackendService.put(`/item/${itemId}/`, data);
}
async function addItem(data = {}) {
  return BackendService.postData('/items/', data);
}
async function getItemCategories(data = {}) {
  return BackendService.getData('/categories/', data);
}
async function addItemCategory(data = {}) {
  return BackendService.postData('/categories/', data);
}
async function uploadCategoryImage(data = {}, id) {
  return BackendService.postData(`/category/${id}/image/`, data);
}
async function uploadItemImage(data = {}, id) {
  return BackendService.postData(`/item/${id}/image/`, data);
}
async function editItemCategory(data = {}, id) {
  return BackendService.put(`/category/${id}/`, data);
}
async function updatePlu() {
  return BackendService.postData('/items/update_plu_text/', '');
}
async function exportExcel() {
  return BackendService.get('/items/export_excel/', {}, {}, 'arraybuffer');
}
async function importToExcel(data) {
  return BackendService.postData('/items/import_excel/', data);
}
async function getItemAdvanced(id) {
  return BackendService.getData(`/items/${id}/advanced/`);
}
async function updateItemAdvanced(data = {}, id) {
  return BackendService.put(`/items/${id}/advanced/`, data);
}
const ItemService = {
  getItems,
  getItemNames,
  updateItem,
  addItem,
  getItemCategories,
  addItemCategory,
  editItemCategory,
  uploadCategoryImage,
  uploadItemImage,
  updatePlu,
  exportExcel,
  importToExcel,
  getItemAdvanced,
  updateItemAdvanced,
};

export default ItemService;
