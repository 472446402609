import { Link } from 'react-router-dom';
import { Container, Typography } from '@material-ui/core';
export default function PageNotFound(props) {
  return (
    <Container style={{display:'flex',alignItems:'center',justifyContent:'center',height:'100vh'}}>
        <Typography component="div" variant="h3">
        This page is not available now. <Link to="/">Click here</Link> to go to
      home.
        </Typography>
      </Container>

  );
}