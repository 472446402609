import style from './style.module.css';

export default function Loader(props) {
  return (
    <div
      className={style.loader}
      style={{ color: props.color || '#3f51b5' }}
    />
  );
}
