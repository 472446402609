import { Alert, AlertTitle } from '@material-ui/lab';
import { Link } from 'react-router-dom';
export default function RestrictionInfo(props){
    const {title,content} = props;
    return(
    <Alert severity="warning" style={{margin:"10px 0"}}>
    <AlertTitle><strong>{title}</strong></AlertTitle>
    {content} - <strong><Link to={'/subscription-plans'}>Upgrade plan</Link></strong>
  </Alert>
    )
}