import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import './App.css';
import DashboardPage from './components/pages/Dashboard/Dashboard';
import SettingsPage from './components/pages/Settings/SettingsPage';
import StockReportPage from './components/pages/stock/StockReport';
import StockDamagePage from './components/pages/StockDamage/StockDamage';
import NewStockDamagePage from './components/pages/StockDamage/NewStockDamage';
import ItemSummaryReportPage from './components/pages/Report/ItemSummary';
import ItemisedBillSummaryReportPage from './components/pages/Report/ItemisedBill';
import BillSummaryReportPage from './components/pages/Report/BillSummary';
import LoginPage from './components/pages/Login/Login';
import ForgetPasswordPage from './components/pages/Login/ForgetPassword';
import NewAccountPage from './components/pages/Login/NewAccount';
import ItemsListPage from './components/pages/Products/ItemsList';
import ItemsCategoriesPage from './components/pages/Products/ItemsCategries';
import DevicesPage from './components/pages/Devices/Devices';
import PurchaseHistoryPage from './components/pages/Purchase/PurchaseHistory';
import AddPurchasePage from './components/pages/Purchase/AddPurchase';
import SubscriptionPage from './components/pages/Subscription&Payment/Subscription';
import PermissionDetail from './components/pages/Settings/PermissionDetail';
import PaymentCallbackPage from './components/pages/Subscription&Payment/PaymentCallback';
import CustomersPage from './components/pages/Customers/Customers';
import ExpensesPage from './components/pages/Expenses/Expenses';
import PageNotFound from './components/pages/Error/PageNotFound';
import PrivateRoute from './components/utils/PrivateRoute';
import SelectedTabProvider from './Context/SelectedTabContext';
import ShopProvider from './Context/ShopContext';
import UserProvider from './Context/UserContext';
import PurchaseAlertProvider from './Context/PurchaseAlertContext';
import StockDamageAlertProvider from './Context/StockDamageAlertContext';
import { ROUTES } from './const';

function App() {
  return (
    <Router>
      <SelectedTabProvider>
        <Switch>
          <Route exact path={ROUTES.ANY} component={LoginPage} />
          <Route exact path={ROUTES.CONSOLE} component={LoginPage} />
          <Route exact path={ROUTES.LOGIN} component={LoginPage} />
          <Route
            exact
            path={ROUTES.RESET_PASSWORD}
            component={ForgetPasswordPage}
          />
          <Route exact path={ROUTES.SIGNUP} component={NewAccountPage} />
          <Route exact path={ROUTES.PAGE_404} component={PageNotFound} />
          <ShopProvider>
            <UserProvider>
              <PrivateRoute
                exact
                path={ROUTES.DASHBOARD}
                component={DashboardPage}
              />
              <PrivateRoute
                exact
                path={ROUTES.ITEMS}
                component={ItemsListPage}
              />
              <PrivateRoute
                exact
                path={ROUTES.CATEGORIES}
                component={ItemsCategoriesPage}
              />
              <PrivateRoute
                exact
                path={ROUTES.DEVICES}
                component={DevicesPage}
              />
              <PrivateRoute
                exact
                path={ROUTES.CUSTOMERS}
                component={CustomersPage}
              />
              <PurchaseAlertProvider>
                <PrivateRoute
                  exact
                  path={ROUTES.PURCHASE_HISTORY}
                  component={PurchaseHistoryPage}
                />
                <PrivateRoute
                  exact
                  path={ROUTES.NEW_PURCHASE}
                  component={AddPurchasePage}
                />
                <PrivateRoute
                  exact
                  path={ROUTES.EDIT_PURCHASE}
                  component={AddPurchasePage}
                />
              </PurchaseAlertProvider>

              <PrivateRoute
                exact
                path={ROUTES.EXPENSES}
                component={ExpensesPage}
              />

              <StockDamageAlertProvider>
                <PrivateRoute
                  exact
                  path={ROUTES.DAMAGE_HISTORY}
                  component={StockDamagePage}
                />
                <PrivateRoute
                  exact
                  path={ROUTES.NEW_DAMAGE}
                  component={NewStockDamagePage}
                />
              </StockDamageAlertProvider>
              <PrivateRoute
                exact
                path={ROUTES.ITEM_SUMMARY}
                component={ItemSummaryReportPage}
              />
              <PrivateRoute
                exact
                path={ROUTES.ITEMISED_BILL}
                component={ItemisedBillSummaryReportPage}
              />
              <PrivateRoute
                exact
                path={ROUTES.BILL_SUMMARY}
                component={BillSummaryReportPage}
              />
              <PrivateRoute
                exact
                path={ROUTES.STOCK_REPORT}
                component={StockReportPage}
              />
              <PrivateRoute
                exact
                path={ROUTES.SETTINGS}
                component={SettingsPage}
              />
              <PrivateRoute
                exact
                path={ROUTES.SUBSCRIPTION_PLANS}
                component={SubscriptionPage}
              />
              <PrivateRoute
                exact
                path={ROUTES.PERMISSION_DETAIL}
                component={PermissionDetail}
              />
              <PrivateRoute
                exact
                path={ROUTES.PAYMENT_CALLBACK}
                component={PaymentCallbackPage}
              />
            </UserProvider>
          </ShopProvider>

          <Route path="/*">
            <Redirect to={ROUTES.PAGE_404} />
          </Route>
        </Switch>
      </SelectedTabProvider>
    </Router>
  );
}

export default App;
