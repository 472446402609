import { createContext, useState } from 'react';
export const PurchaseAlertContext = createContext();

export default function PurchaseAlertProvider(props) {
  const [purchaseMsg, setPurchaseMsg] = useState({
    error: '',
    success: '',
  });

  return (
    <PurchaseAlertContext.Provider value={{ purchaseMsg, setPurchaseMsg }}>
      {props.children}
    </PurchaseAlertContext.Provider>
  );
}
