import BackendService from './BackendService';

async function getItemisedBillReport(data = {}) {
  return BackendService.postData('/reports/items/', data);
}
async function getItemisedBillExcel(data = {}) {
  return BackendService.post(
    '/reports/items/',
    data,
    undefined,
    undefined,
    'arraybuffer'
  );
}
async function getItemisedBillCsv(data = {}) {
  return BackendService.post(
    '/reports/items/',
    data,
    undefined,
    undefined,
    'arraybuffer'
  );
}

const ItemisedBillReportService = {
  getItemisedBillReport,
  getItemisedBillExcel,
  getItemisedBillCsv,
};

export default ItemisedBillReportService;
