import React, { useState, useEffect, useContext } from 'react';
import styles from './styles.module.css';
import { Link, useHistory } from 'react-router-dom';
import {
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Grid,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import DeleteIcon from '@material-ui/icons/Delete';
import DateTimeHelpers from '../../../helpers/DateTimeHelpers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import ItemService from '../../../services/ItemService';
import StockDamageService from '../../../services/StockDamageService';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import { AlertSetter } from '../../utils/Alert/AlertSetter';
import { ROUTES } from '../../../const';
import { StockDamageAlertContext } from '../../../Context/StockDamageAlertContext';

function NewStockDamage() {
  const { stockDamageMsg, setStockDamageMsg } = useContext(
    StockDamageAlertContext
  );
  const [items, setItems] = useState([]);
  const [item, setItem] = useState('');
  const [itemPlu, setItemPlu] = useState('');
  const [itemName, setItemName] = useState('');
  const [quantity, setQuantity] = useState('1');
  const [cost, setCost] = useState('0');
  const [date, setDate] = useState(new Date());
  const [totalAmount, setTotalAmount] = useState('0');
  const [damagedItems, setDamagedItems] = useState([]);
  const history = useHistory();
  useEffect(() => {
    const getItems = async () => {
      const res = await ItemService.getItems();
      setItems(res);
    };
    getItems();
  }, []);
  useEffect(() => {
    setTotalAmount(Number(cost) * Number(quantity));
  }, [cost, quantity]);
  const resetItemFields = () => {
    setItemName('');
    setItemPlu('');
    setQuantity('1');
    setCost('0');
    setTotalAmount('0');
  };
  const addItem = () => {
    setDamagedItems([
      ...damagedItems,
      {
        item: item.id,
        plu: Number(itemPlu),
        item_name: itemName,
        quantity: Number(quantity),
        rate: cost,
      },
    ]);
    resetItemFields();
  };
  const handleAddDamage = async () => {
    try {
      const data = {
        date: DateTimeHelpers.convertDateToDMY(date),
        damages: damagedItems,
      };
      await StockDamageService.createStockDamage(data);
      AlertSetter(setStockDamageMsg, {
        ...stockDamageMsg,
        success: 'Damage added successfully',
      });
    } catch (err) {
      AlertSetter(setStockDamageMsg, {
        ...stockDamageMsg,
        error: err.message,
      });
    }

    history.push(ROUTES.DAMAGE_HISTORY);
  };
  const dltItem = (item) => {
    setDamagedItems(damagedItems.filter((itm) => item.item !== itm.item));
  };

  return (
    <div className={styles.contentWrapper}>
      <div className={styles.titleSec}>
        <h2 className={styles.title}>
          Stock Damage<span className={styles.menuTitle}>Management</span>
        </h2>
      </div>
      <div className={styles.changeable}>
        <div className={styles.filterSec}>
          <div className={styles.headTitle}>
            <h2 className={styles.subTitle}>New damage</h2>
          </div>
        </div>
        <Grid container justify="flex-end" alignItems="center">
          <Grid item xs={3} sm={3} className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>Damaged Date*</h3>
          </Grid>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="outlined"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-damage"
              max={new Date()}
              value={date}
              onChange={(date) => {
                setDate(date);
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </div>
      <TableContainer style={{ marginTop: 4 }} component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell className={styles.columnCell}>PLU</TableCell>
              <TableCell className={styles.nameColumn}>Item Name</TableCell>
              <TableCell className={styles.columnCell}>Cost</TableCell>
              <TableCell className={styles.columnCell}>Quantity</TableCell>
              <TableCell className={styles.columnCell}>Total</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {damagedItems &&
              damagedItems.map((item) => (
                <TableRow key={item.plu}>
                  <TableCell>{item.plu}</TableCell>
                  <TableCell>{item.item_name}</TableCell>
                  <TableCell>{item.quantity}</TableCell>
                  <TableCell>{item.rate}</TableCell>
                  <TableCell>{Number(item.rate * item.quantity)}</TableCell>
                  <TableCell>
                    <Button variant="contained" onClick={() => dltItem(item)}>
                      <DeleteIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}

            <TableRow key="input row">
              <TableCell>
                <Autocomplete
                  value={item.plu}
                  onChange={(event, newItem) => {
                    setItem(newItem);
                    setItemName(newItem.name);
                    setCost(String(newItem.cost));
                  }}
                  disableClearable
                  inputValue={itemPlu}
                  onInputChange={(e, value) => {
                    setItemPlu(value);
                  }}
                  getOptionLabel={(option) => `${option.plu}`}
                  options={items}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="PLU"
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  )}
                />
              </TableCell>
              <TableCell>
                <Autocomplete
                  value={item.name}
                  onChange={(event, newItem) => {
                    setItem(newItem);
                    setItemPlu(String(newItem.plu));
                    setCost(String(newItem.cost));
                  }}
                  disableClearable
                  inputValue={itemName}
                  onInputChange={(e, value) => {
                    setItemName(value);
                  }}
                  getOptionLabel={(option) => `${option.name}`}
                  options={items}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search by Name"
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  )}
                />
              </TableCell>
              <TableCell>{cost}</TableCell>
              <TableCell>
                <TextField
                  label="Quantity"
                  variant="outlined"
                  size="small"
                  type="number"
                  value={quantity}
                  onChange={(e) => {
                    setQuantity(e.target.value);
                  }}
                />
              </TableCell>
              <TableCell>{totalAmount}</TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  disabled={itemPlu === '' || itemName === '' || cost === ''}
                  onClick={addItem}
                >
                  <LibraryAddIcon />
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container className={styles.submitSection} justify="flex-end">
        <Link to={ROUTES.DAMAGE_HISTORY} className={styles.link}>
          <Button
            variant="contained"
            color="secondary"
            className={styles.closeBtn}
          >
            Cancel
          </Button>
        </Link>
        <Button
          variant="contained"
          color="primary"
          style={{ backgroundColor: '#00a65a' }}
          disabled={date === '' || damagedItems === ''}
          onClick={handleAddDamage}
        >
          Save Damage
        </Button>
      </Grid>
    </div>
  );
}

export default withConsoleBase(NewStockDamage);
