import { Grid, TextField, Button, CardMedia } from '@material-ui/core';
import styles from './styles.module.css';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from 'react';
import ItemService from '../../services/ItemService';
import { AlertSetter } from '../utils/Alert/AlertSetter';
import { DialogActions } from '@mui/material';

export default function AddCategoryDialog(props) {
  const { toggleEditTab, editData, setSuccessMsg, setErr } = props;
  const [categoryName, setCategoryName] = useState(editData.name);
  const [categoryDesc, setCategoryDesc] = useState(editData.desc);
  const [imageData, setImageData] = useState('');
  const uploadImage = (image, categId) => {
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onloadend = async () => {
      const data = {
        image: reader.result,
      };
      await ItemService.uploadCategoryImage(data, categId);
    };
  };
  const handleEditCategory = async () => {
    try {
      const data = {
        ...editData,
        name: categoryName,
        description: categoryDesc,
      };
      await ItemService.editItemCategory(data, editData.id);
      if (imageData) {
        uploadImage(imageData, editData.id);
      }
      AlertSetter(setSuccessMsg, 'Category updated successfully');
    } catch (err) {
      AlertSetter(setErr, err.message);
    }
    toggleEditTab();
  };
  return (
    <div className={styles.popUp}>
      <div className={styles.contentWrapper}>
        <div className={styles.headSec}>
          <h2 className={styles.editTitle}>Edit category</h2>
          <CloseIcon onClick={toggleEditTab} />
        </div>
        <div className={styles.inputSec}>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid
              item
              xs={4}
              sm={4}
              spacing={2}
              justify="flex-end"
              className={styles.inputLabelContainer}
            >
              <h3 className={styles.inputLabel}>Category Name</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Category Name"
                variant="outlined"
                size="small"
                fullWidth
                value={categoryName}
                onChange={(e) => {
                  setCategoryName(e.target.value);
                }}
              />
            </Grid>
          </Grid>

          <Grid container className={styles.inputRow} alignItems="center">
            <Grid
              item
              xs={4}
              sm={4}
              spacing={2}
              justify="flex-end"
              className={styles.inputLabelContainer}
            >
              <h3 className={styles.inputLabel}>Category Description</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Category Description"
                variant="outlined"
                size="small"
                fullWidth
                value={categoryDesc}
                onChange={(e) => {
                  setCategoryDesc(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid
              item
              xs={4}
              sm={4}
              spacing={2}
              justify="flex-end"
              className={styles.inputLabelContainer}
            >
              <h3 className={styles.inputLabel}>Category Image</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <div className={styles.imageContainer}>
                <CardMedia
                  image={
                    imageData ? URL.createObjectURL(imageData) : editData.image
                  }
                  className={styles.image}
                />
              </div>
              <input
                accept="image/*"
                type="file"
                hidden
                id="upload"
                onChange={(e) => {
                  setImageData(e.target.files[0]);
                }}
              />
              <Button variant="contained" className={styles.uploadBtn}>
                <label for="upload">select</label>
              </Button>
            </Grid>
          </Grid>

          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              onClick={toggleEditTab}
              className={styles.closeBtn}
            >
              cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={categoryName === ''}
              onClick={handleEditCategory}
            >
              Update Category
            </Button>
          </DialogActions>
        </div>
      </div>
    </div>
  );
}
