import React, { useState, useEffect, useContext } from 'react';
import {
  Grid,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import styles from './styles.module.css';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import DeleteIcon from '@material-ui/icons/Delete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ItemService from '../../../services/ItemService';
import PurchaseService from '../../../services/PurchaseService';
import DateTimeHelpers from '../../../helpers/DateTimeHelpers';
import { Link, useHistory, useParams } from 'react-router-dom';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import { PurchaseAlertContext } from '../../../Context/PurchaseAlertContext';
import { AlertSetter } from '../../utils/Alert/AlertSetter';
import { ROUTES } from '../../../const';

function AddPurchase() {
  const { id } = useParams();
  const history = useHistory();
  const { purchaseMsg, setPurchaseMsg } = useContext(PurchaseAlertContext);
  const [editData, setEditData] = useState('');
  const [vendorName, setVendorName] = useState('');
  const [purchaseDate, setPurchaseDate] = useState(new Date());
  const [items, setItems] = useState();
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [item, setItem] = useState('');
  const [billDate, setBillDate] = useState(new Date());
  const [itemPlu, setItemPlu] = useState('');
  const [itemName, setItemName] = useState('');
  const [cost, setCost] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [gst, setGst] = useState(0);
  const [totalAmount, setTotalAmount] = useState('');
  const [purchasedItems, setPurchasedItems] = useState([]);
  const [removedItems, setRemovedItems] = useState('');

  useEffect(() => {
    const getItems = async () => {
      const res = await ItemService.getItems();
      setItems(res);
    };
    getItems();
  }, []);

  useEffect(() => {
    const getPurchaseData = async () => {
      const res = await PurchaseService.getPurchase(id);
      setEditData(res);
      setVendorName(res.vendor);
      setInvoiceNumber(res.invoice_no);
      setBillDate(DateTimeHelpers.convertDateToMDY(res.bill_date));
      setPurchaseDate(DateTimeHelpers.convertDateToMDY(res.date));
      setPurchasedItems(res.items);
    };
    if (id) {
      getPurchaseData();
    }
  }, [id]);

  useEffect(() => {
    setTotalAmount(Number(cost) * Number(quantity) + Number(gst));
  }, [cost, quantity, gst]);

  const resetItemFields = () => {
    setItemName('');
    setItemPlu('');
    setCost('');
    setQuantity('');
    setGst('');
    setTotalAmount('');
    setItem('');
  };

  const addItem = () => {
    let data = {
      plu: item.plu,
      item_name: item.name,
      item: item.id,
      rate: Number(cost),
      quantity: Number(quantity),
      vat: Number(gst),
    };
    if (editData) {
      data = {
        ...data,
        purchase: editData.id,
        new_item: true,
      };
    }
    setPurchasedItems([...purchasedItems, data]);
    resetItemFields();
  };

  const handleAddPurchase = async () => {
    try {
      const data = {
        vendor: vendorName,
        invoice_no: invoiceNumber,
        bill_date: DateTimeHelpers.convertDateToDMY(billDate),
        date: DateTimeHelpers.convertDateToDMY(purchaseDate),
        items: purchasedItems,
      };
      await PurchaseService.addPurchase(data);
      AlertSetter(setPurchaseMsg, {
        ...purchaseMsg,
        success: 'Purchase Added successfully',
      });
    } catch (err) {
      AlertSetter(setPurchaseMsg, {
        ...purchaseMsg,
        error: err.message,
      });
    }

    history.push(ROUTES.PURCHASE_HISTORY);
  };

  const dltItem = (item) => {
    if (item.hasOwnProperty('id')) {
      setRemovedItems((old) => [...old, item.id]);
    }
    setPurchasedItems(
      purchasedItems.filter(
        (itm) => itm.rate !== item.rate && itm.plu !== item.plu
      )
    );
  };

  const getItemName = (id) => {
    const name = items.filter((item) => item.id === id)[0].name;
    return name;
  };

  const handleEditPurchase = async () => {
    try {
      const olditems = purchasedItems.filter((item) =>
        item.hasOwnProperty('id')
      );
      const newItems = purchasedItems.filter(
        (item) => !item.hasOwnProperty('id')
      );
      const data = {
        ...editData,
        vendor: vendorName,
        invoice_no: invoiceNumber,
        bill_date: DateTimeHelpers.convertDateToDMY(billDate),
        date: DateTimeHelpers.convertDateToDMY(purchaseDate),
        items: olditems,
        new_items: newItems,
        remove_items: removedItems,
      };
      await PurchaseService.updatePurchase(data, id);
      AlertSetter(setPurchaseMsg, {
        ...purchaseMsg,
        success: 'Purchase updated successfully',
      });
    } catch (err) {
      AlertSetter(setPurchaseMsg, {
        ...purchaseMsg,
        error: err.message,
      });
    }
    history.push(ROUTES.PURCHASE_HISTORY);
  };

  return (
    <div className={styles.contentWrapper}>
      <div className={styles.titleSec}>
        <h2 className={styles.title}>
          Purchase<span className={styles.menuTitle}>Management</span>
        </h2>
      </div>
      <div className={styles.changeable}>
        <div className={styles.filterSec}>
          <div className={styles.headTitle}>
            <h2 className={styles.subTitle}>New Purchase</h2>
          </div>
        </div>
        <Grid container justify="flex-end" alignItems="center">
          <Grid
            item
            xs={2}
            sm={2}
            justify="flex-end"
            className={styles.inputLabelContainer}
          >
            <h3 className={styles.inputLabel}>Vendor Name</h3>
          </Grid>
          <Grid item xs={4} sm={4}>
            <TextField
              label="Vendor Name"
              variant="outlined"
              size="small"
              fullWidth
              value={vendorName}
              onChange={(e) => {
                setVendorName(e.target.value);
              }}
            />
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            spacing={2}
            justify="flex-end"
            className={styles.inputLabelContainer}
          >
            <h3 className={styles.inputLabel}>Purchase Date</h3>
          </Grid>
          <Grid item xs={3} sm={3}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="outlined"
                format="dd/MM/yyyy"
                margin="normal"
                error={false}
                id="date-purchase"
                value={purchaseDate}
                onChange={(date) => {
                  setPurchaseDate(date);
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
        <Grid container justify="flex-end" alignItems="center">
          <Grid
            item
            xs={2}
            sm={2}
            justify="flex-end"
            className={styles.inputLabelContainer}
          >
            <h3 className={styles.inputLabel}>Invoice Number</h3>
          </Grid>
          <Grid item xs={4} sm={4}>
            <TextField
              label="Invoice Number"
              variant="outlined"
              size="small"
              fullWidth
              value={invoiceNumber}
              onChange={(e) => {
                setInvoiceNumber(e.target.value);
              }}
            />
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            spacing={2}
            justify="flex-end"
            className={styles.inputLabelContainer}
          >
            <h3 className={styles.inputLabel}>Bill Date</h3>
          </Grid>
          <Grid item xs={3} sm={3}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="outlined"
                format="dd/MM/yyyy"
                margin="normal"
                id="date-bill"
                value={billDate}
                onChange={(date) => {
                  setBillDate(date);
                }}
                KeyboardButtonProps={{
                  'aria-label': 'Change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </div>
      <TableContainer style={{ marginTop: 4 }} component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell className={styles.columnCell}>Item PLU</TableCell>
              <TableCell className={styles.nameColumn}>Item Name</TableCell>
              <TableCell className={styles.columnCell}>Cost</TableCell>
              <TableCell className={styles.columnCell}>Quantity</TableCell>
              <TableCell className={styles.columnCell}>GST</TableCell>
              <TableCell className={styles.columnCell}>Total</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {purchasedItems &&
              items &&
              purchasedItems.map((item) => (
                <TableRow key={item.plu}>
                  <TableCell>{item.plu}</TableCell>
                  <TableCell>
                    {item.hasOwnProperty('id')
                      ? getItemName(item.item)
                      : item.item_name}
                  </TableCell>
                  <TableCell>{item.rate}</TableCell>
                  <TableCell>{item.quantity}</TableCell>
                  <TableCell>{item.vat}</TableCell>
                  <TableCell>
                    {Number(item.rate) * Number(item.quantity) +
                      Number(item.vat)}
                  </TableCell>
                  <TableCell>
                    <Button variant="contained" onClick={() => dltItem(item)}>
                      <DeleteIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}

            <TableRow key="input row">
              <TableCell>
                <Autocomplete
                  value={item.plu}
                  onChange={(event, newItem) => {
                    setItem(newItem);
                    setItemName(newItem.name);
                  }}
                  disableClearable
                  inputValue={itemPlu}
                  onInputChange={(e, value) => {
                    setItemPlu(value);
                  }}
                  getOptionLabel={(option) => `${option.plu}`}
                  options={items}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="PLU"
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  )}
                />
              </TableCell>
              <TableCell>
                <Autocomplete
                  value={item.name}
                  onChange={(event, newItem) => {
                    setItem(newItem);
                    setItemPlu(newItem.plu);
                  }}
                  disableClearable
                  inputValue={itemName}
                  onInputChange={(e, value) => {
                    setItemName(value);
                  }}
                  getOptionLabel={(option) => `${option.name}`}
                  options={items}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search by Name"
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  )}
                />
              </TableCell>
              <TableCell>
                <TextField
                  label="Cost"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={cost}
                  onChange={(e) => {
                    setCost(e.target.value);
                  }}
                  type="number"
                />
              </TableCell>
              <TableCell>
                <TextField
                  label="Quantity"
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="number"
                  value={quantity}
                  onChange={(e) => {
                    setQuantity(e.target.value);
                  }}
                />
              </TableCell>
              <TableCell>
                <TextField
                  label="GST"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={gst}
                  type="number"
                  onChange={(e) => {
                    setGst(e.target.value);
                  }}
                />
              </TableCell>
              <TableCell>{totalAmount}</TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  disabled={itemPlu === '' || itemName === '' || cost === ''}
                  onClick={addItem}
                >
                  <LibraryAddIcon />
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container className={styles.submitSection} justify="flex-end">
        <Link to={ROUTES.PURCHASE_HISTORY} className={styles.link}>
          <Button
            variant="contained"
            color="secondary"
            className={styles.closeBtn}
          >
            Cancel
          </Button>
        </Link>
        <Button
          variant="contained"
          color="primary"
          style={{ backgroundColor: '#00a65a' }}
          disabled={
            vendorName === '' ||
            invoiceNumber === '' ||
            billDate === '' ||
            purchaseDate === '' ||
            purchasedItems.length === 0
          }
          onClick={editData ? handleEditPurchase : handleAddPurchase}
        >
          {editData ? 'Update Purchase' : 'Save Purchase'}
        </Button>
      </Grid>
    </div>
  );
}

export default withConsoleBase(AddPurchase);
