import styles from './styles.module.css';
import React, { useState, useEffect, useContext } from 'react';
import { Grid, Button } from '@material-ui/core';
import DataTable from '../../utils/DataTable/';
import CreateIcon from '@material-ui/icons/Create';
import NewUserDialog from '../../popups/NewUserDialog';
import { useHistory } from 'react-router';
import { AlertSetter } from '../../utils/Alert/AlertSetter';
import { ShopContext } from '../../../Context/ShopContext';
import { UserContext } from '../../../Context/UserContext';
import Info from '../../utils/Alert/Info';
import Error from '../../utils/Alert/Error';
import Success from '../../utils/Alert/Success';
import setDelay from '../../../helpers/LoadingDelay';
import ConfirmPopup from '../../utils/Alert/ConfirmPopup';
import UserService from '../../../services/UserService';
import { USER_PERMISSIONS, SUBSCRIPTION_TYPE } from '../../../const';
import RestrictionInfo from '../../utils/Alert/RestrictionInfo';

export default function ShopUsersList() {
  const { shop } = useContext(ShopContext);
  const { user } = useContext(UserContext);
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const subscriptionType = shop?.subscription?.type;
  const [deleteData, setDeleteData] = useState(false);
  const [addTab, setAddTab] = useState('');
  const [shopUsers, setShopUsers] = useState('');
  const [errorMsg, setErrorMsg] = useState();
  const [successMsg, setSuccessMsg] = useState();
  const history = useHistory();

  const userHasUsersWritePermission =
    user?.shop_permissions?.users_permission === USER_PERMISSIONS.WRITE;

  useEffect(() => {
    loadUsersList();
  }, [subscriptionType]);

  const loadUsersList = async () => {
    setLoadingIndicator(true);
    try {
      const resp = await UserService.getShopUsers();
      setShopUsers(resp);
    } catch (err) {
      AlertSetter(setErrorMsg, err.message);
    }
    setLoadingIndicator(false);
  };

  const toggleAddTab = (move = '') => {
    if (move === 'save') {
      loadUsersList();
    }
    addTab ? setAddTab('') : setAddTab('open');
  };

  const handleDetailClick = (row) => {
    history.push(`/settings/shop-users/${row.id}`, row);
  };

  const handleDeleteClick = (data) => setDeleteData(data);

  const handleDltUser = async () => {
    setLoadingIndicator(true);
    try {
      await UserService.deleteUser(deleteData.id);
      loadUsersList().then();
      AlertSetter(setSuccessMsg, 'User deleted successufully');
    } catch (err) {
      AlertSetter(setErrorMsg, err.message);
    }
    setDelay(setLoadingIndicator);
  };

  const headerData = [
    {
      label: 'Username',
      id: 'username',
      type: 'text',
    },
    {
      label: 'First Name',
      id: 'first_name',
      type: 'text',
    },
    {
      label: 'Last Name',
      id: 'last_name',
      type: 'text',
    },
  ];
  if (user?.shop_permissions?.users_permission === USER_PERMISSIONS.WRITE) {
    headerData.push({
      label: 'View Details',
      title: 'View Detail',
      id: 'detail',
      type: 'button',
      clickHandler: handleDetailClick,
    });
    headerData.push({
      label: 'Delete',
      id: 'deleteBtn',
      type: 'button',
      title: 'Delete',
      clickHandler: handleDeleteClick,
    });
  }

  return (
    <>
      {subscriptionType && subscriptionType === SUBSCRIPTION_TYPE.BASIC ? (
        <RestrictionInfo
          title={'Feature not available '}
          content={'To get short key options upgrade subscription to premium'}
        />
      ) : (
        <div className={styles.contentLayout}>
          <div className={styles.shorKeyContainer}>
            <ConfirmPopup
              data={deleteData}
              handleClose={handleDeleteClick}
              handleOkay={handleDltUser}
              alertTitle={'Confirm delete'}
              successBtnName={'delete'}
              alertContent={
                "Deleted user can't be restored, do you want to delete the user?"
              }
            />
            <Grid container spacing={1} className={styles.flexSpaceBw}>
              <Grid item style={{ marginBottom: 15 }}>
                <span className={styles.pageHead}>Shop Users</span>
              </Grid>
              {userHasUsersWritePermission && (
                <Grid item style={{ marginBottom: 15 }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    className={styles.actionBtn}
                    onClick={toggleAddTab}
                  >
                    <CreateIcon className={styles.actionBtnIcon} />
                    New User
                  </Button>
                </Grid>
              )}
            </Grid>
            <Grid>
              {errorMsg && (
                <div className={styles.marginTop}>
                  <Error title={errorMsg} />
                </div>
              )}
              {successMsg && (
                <div className={styles.marginTop}>
                  <Success title={successMsg} />
                </div>
              )}
              {!loadingIndicator &&
                (shopUsers.length ? (
                  <DataTable
                    columns={headerData}
                    rows={shopUsers}
                    rowKey="row.id"
                  />
                ) : (
                  <Info
                    title={'You have no user to list'}
                    content={
                      "You haven't any user to list with given properties"
                    }
                  />
                ))}
            </Grid>
          </div>
          {addTab && (
            <NewUserDialog
              toggleAddTab={toggleAddTab}
              setErrorMsg={setErrorMsg}
              setSuccessMsg={setSuccessMsg}
            />
          )}
        </div>
      )}
    </>
  );
}
