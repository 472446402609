const getString = (data, headers) => {
  const fields = Object.keys(headers || data[0]);
  const head = fields.map((key) => headers?.[key] || key).join(',') + '\n';
  const csv = data
    .map((datum) => fields.map((key) => datum[key] || '').join(','))
    .join('\n');
  return head + csv;
};

const CsvHelper = { getString };
export default CsvHelper;
